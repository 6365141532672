import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Tabs,
  Tab,
  Box,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { Type } from "../../../../redux/types/types";

interface CreateTypeDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (typeData: Omit<Type, "id">) => void;
}

export default function CreateTypeDialog({
  open,
  onClose,
  onSave,
}: CreateTypeDialogProps) {
  const intl = useIntl();
  const [currentTab, setCurrentTab] = useState<"en" | "uk" | "fr">("en");
  const [newType, setNewType] = useState<Omit<Type, "id">>({
    name: { en: "", uk: "", fr: "" },
  });

  const handleChange =
    (language: keyof Type["name"]) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setNewType({
        ...newType,
        name: {
          ...newType.name,
          [language]: e.target.value,
        },
      });
    };

  const handleSave = () => {
    onSave(newType);
    setNewType({
      name: { en: "", uk: "", fr: "" },
    });
    setCurrentTab("en");
  };

  const isEnglishEmpty = newType.name.en.trim() === "";

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">
        <FormattedMessage id="admin.dashboard.createType" />
      </DialogTitle>
      <DialogContent>
        <Tabs
          value={currentTab}
          onChange={(_, newValue: "en" | "uk" | "fr") =>
            setCurrentTab(newValue)
          }
          aria-label="language tabs"
        >
          <Tab
            label={intl.formatMessage({ id: "common.english" })}
            value="en"
          />
          <Tab
            label={intl.formatMessage({ id: "common.ukrainian" })}
            value="uk"
            disabled={isEnglishEmpty}
          />
          <Tab
            label={intl.formatMessage({ id: "common.french" })}
            value="fr"
            disabled={isEnglishEmpty}
          />
        </Tabs>
        <Box sx={{ mt: 2 }}>
          {currentTab === "en" && (
            <TextField
              autoFocus
              margin="dense"
              id="name-en"
              label={intl.formatMessage({ id: "admin.dashboard.nameEn" })}
              type="text"
              fullWidth
              value={newType.name.en}
              onChange={handleChange("en")}
            />
          )}
          {currentTab === "uk" && (
            <TextField
              margin="dense"
              id="name-uk"
              label={intl.formatMessage({ id: "admin.dashboard.nameUk" })}
              type="text"
              fullWidth
              value={newType.name.uk}
              onChange={handleChange("uk")}
            />
          )}
          {currentTab === "fr" && (
            <TextField
              margin="dense"
              id="name-fr"
              label={intl.formatMessage({ id: "admin.dashboard.nameFr" })}
              type="text"
              fullWidth
              value={newType.name.fr}
              onChange={handleChange("fr")}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <FormattedMessage id="admin.dashboard.cancel" />
        </Button>
        <Button onClick={handleSave}>
          <FormattedMessage id="admin.dashboard.create" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
