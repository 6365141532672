import React, { useEffect } from "react";
import {
  RouterProvider,
  createBrowserRouter,
  Navigate,
} from "react-router-dom";
import { useDispatch } from "react-redux";

import { AppDispatch, RootState } from "./redux/store";
import { getProfile, logOut } from "./redux/auth/authOperations";
import PublicRoute from "./routes/PublicRoute";
import PrivateRoute from "./routes/PrivateRoute";
import AdminRoute from "./routes/AdminRoute";
import SignInSide from "./components/SignInSide/SignInSide";
import SignInForm from "./components/SignInSide/SignInForm/SignInForm";
import SignUpForm from "./components/SignInSide/SignUpForm/SignUpForm";
import ForgotPassword from "./components/SignInSide/ForgotPassword/ForgotPassword";
import ResetPassword from "./components/SignInSide/ResetPassword/ResetPassword";
import ConfirmEmailChange from "./components/ConfirmEmailChange/ConfirmEmailChange";
import AdminSignInForm from "./components/AdminSignInForm/AdminSignInForm";
import HomePage from "./pages/HomePage/HomePage";
import Profile from "./components/Profile/Profile";
import AdminDashboard from "./components/AdminDashboard/AdminDashboard";
import PrivateEnrollPage from "./pages/PrivateEnrollPage/PrivateEnrollPage";
import EnrollPage from "./pages/EnrollPage/EnrollPage";
import RootRedirect from "./routes/RootRedirect";
import AuthWrapper from "./routes/AuthWrapper";
import CreatePassword from "./components/SignInSide/CreatePassword/CreatePassword";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import Attendees from "./components/Attendees/Attendees";
import MyClasses from "./components/MyClasses/MyClasses";
import DashboardLayout from "./components/Layout/DashboardLayout/DashboardLayout";
import MySchedules from "./components/MySchedules/MySchedules";
import UpcomingClasses from "./components/UpcomingClasses/UpcomingClasses";

function App() {
  const dispatch = useDispatch<AppDispatch>();
  const token = useSelector((state: RootState) => state.auth.token);
  const isInstructor = useSelector(
    (state: RootState) => state.auth.isInstructor
  );

  useEffect(() => {
    if (token) {
      dispatch(getProfile())
        .unwrap()
        .catch(() => {
          Cookies.remove("authToken");
          dispatch(logOut());
        });
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (!localStorage.getItem("mode")) {
      localStorage.setItem("mode", "light");
    }
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <RootRedirect />,
    },
    {
      path: "/auth",
      element: <PublicRoute />,
      children: [
        {
          index: true,
          element: <Navigate to="/auth/signin" replace />,
        },
        {
          path: "",
          element: (
            <AuthWrapper>
              <SignInSide />
            </AuthWrapper>
          ),
          children: [
            { path: "signin", element: <SignInForm /> },
            { path: "signup", element: <SignUpForm /> },
            { path: "forgot-password", element: <ForgotPassword /> },
            { path: "reset-password", element: <ResetPassword /> },
            { path: "create-password", element: <CreatePassword /> },
            { path: "confirm-email-change", element: <ConfirmEmailChange /> },
          ],
        },
      ],
    },
    {
      path: "/admin/signin",
      element: <AdminSignInForm />,
    },
    {
      path: "/admin",
      element: <AdminRoute />,
      children: [
        {
          index: true,
          element: <Navigate to="/admin/dashboard" replace />,
        },
        {
          path: "dashboard",
          element: <AdminDashboard />,
        },
      ],
    },
    {
      path: "/home/*",
      element: <PrivateRoute />,
      children: [
        {
          path: "",
          element: <HomePage />,
        },
      ],
    },
    {
      path: "/",
      element: <PrivateRoute />,
      children: [
        {
          path: "/",
          element: <DashboardLayout />,
          children: isInstructor
            ? [
                { path: "profile", element: <Profile /> },
                { path: "my-classes", element: <MyClasses /> },
                { path: "upcoming-classes", element: <UpcomingClasses /> },
                { path: "my-schedules", element: <MySchedules /> },
              ]
            : [
                { path: "profile", element: <Profile /> },
                { path: "attendees", element: <Attendees /> },
                { path: "my-classes", element: <MyClasses /> },
                { path: "upcoming-classes", element: <UpcomingClasses /> },
                { path: "my-schedules", element: <MySchedules /> },
              ],
        },
      ],
    },
    {
      path: "/enroll/details",
      element: <PrivateRoute />,
      children: [
        {
          path: "",
          element: <PrivateEnrollPage />,
        },
      ],
    },
    {
      path: "/enroll",
      element: <EnrollPage />,
    },
    {
      path: "*",
      element: <Navigate to="/" replace />,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
