import api from "./axiosInstance";

export const confirmEmailChangeApi = async (token: string) => {
  const response = await api.post("/auth/confirm-email-change", { token });
  return response.data;
};

export const forgotPasswordApi = async (email: string) => {
  const response = await api.post("/auth/forgot-password", { email });
  return response.data;
};

export const resetPasswordApi = async (token: string, password: string) => {
  const response = await api.post("/auth/reset-password", { token, password });
  return response.data;
};

export const registerUserApi = async (userData: {
  email: string;
  password: string;
  firstname: string;
  lastname: string;
  phone: string;
}) => {
  const response = await api.post("/auth/register", userData);
  return response.data;
};

export const loginUserApi = async (loginData: {
  email: string;
  password: string;
}) => {
  const response = await api.post("/auth/login", loginData);
  return response.data;
};

export const loginInstructorApi = async (loginData: {
  email: string;
  password: string;
}) => {
  const response = await api.post("/auth/instructor/login", loginData);
  return response.data;
};

export const getProfileApi = async () => {
  const response = await api.get("/auth/profile");
  return response.data;
};

export const updateProfileApi = async (profileData: {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
}) => {
  const response = await api.patch("/auth/profile", profileData);
  return response.data;
};

export const googleAuthApi = async (token: string) => {
  const response = await api.post("/auth/google", { token });
  return response.data;
};

export const appleAuthApi = async (body: any) => {
  const response = await api.post("/auth/apple", { ...body });
  return response.data;
};

export const loginAdminApi = async (credentials: {
  email: string;
  password: string;
}) => {
  const response = await api.post("/auth/admin/login", credentials);
  return response.data;
};
