import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Link as LinkMaterial,
  FormControlLabel,
  Checkbox,
  Alert,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useDispatch } from "react-redux";
import { GoogleLogin } from "@react-oauth/google";
import AppleSignInButton from "react-apple-signin-auth";
import { Link } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { FormattedMessage, useIntl } from "react-intl";

import { AppDispatch } from "../../../redux/store";
import {
  appleAuth,
  googleAuth,
  loginUser,
} from "../../../redux/auth/authOperations";
import GoogleOAuthConfig from "../../GoogleOAuthConfig/GoogleOAuthConfig";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      <FormattedMessage
        id="copyright"
        values={{
          year: new Date().getFullYear(),
          website: (chunks: any) => (
            <LinkMaterial color="inherit" href="https://mui.com/">
              {chunks}
            </LinkMaterial>
          ),
        }}
      />
    </Typography>
  );
}

export default function SignInForm() {
  const dispatch = useDispatch<AppDispatch>();
  const [serverError, setServerError] = useState<string | null>(null);
  const intl = useIntl();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email(intl.formatMessage({ id: "signIn.invalidEmail" }))
      .required(intl.formatMessage({ id: "signIn.emailRequired" })),
    password: Yup.string().required(
      intl.formatMessage({ id: "signIn.passwordRequired" })
    ),
    remember: Yup.boolean(),
  });

  const handleSubmit = async (values: {
    email: string;
    password: string;
    remember: boolean;
  }) => {
    try {
      await dispatch(loginUser(values)).unwrap();
      setServerError(null);
    } catch (error: any) {
      setServerError(
        error || intl.formatMessage({ id: "signIn.genericErrorMessage" })
      );
    }
  };

  const handleSuccessGoogle = async (response: any) => {
    try {
      await dispatch(googleAuth({ token: response.credential })).unwrap();
      setServerError(null);
    } catch (error: any) {
      setServerError(
        error.message ||
          intl.formatMessage({ id: "signIn.googleAuthFailedMessage" })
      );
    }
  };

  const handleFailure = () => {
    setServerError(intl.formatMessage({ id: "signIn.loginFailedMessage" }));
  };

  const handleAppleCallback = async (response: any) => {
    try {
      await dispatch(
        appleAuth({
          body: { ...response.authorization, user: response.user || null },
        })
      ).unwrap();
      setServerError(null);
    } catch (error: any) {
      setServerError(
        error.message ||
          intl.formatMessage({ id: "signIn.appleAuthFailedMessage" })
      );
    }
  };

  return (
    <Box
      sx={{
        my: 12,
        mx: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        <FormattedMessage id="signIn.title" />
      </Typography>
      {serverError && (
        <Alert severity="error" sx={{ mt: 2, width: "100%" }}>
          {serverError}
        </Alert>
      )}
      <Formik
        initialValues={{ email: "", password: "", remember: false }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form noValidate style={{ width: "100%" }}>
            <Field
              as={TextField}
              margin="normal"
              required
              fullWidth
              id="email"
              label={intl.formatMessage({ id: "signIn.emailLabel" })}
              name="email"
              autoComplete="email"
              error={touched.email && !!errors.email}
              helperText={touched.email && errors.email}
            />
            <Field
              as={TextField}
              margin="normal"
              required
              fullWidth
              name="password"
              label={intl.formatMessage({ id: "signIn.passwordLabel" })}
              type="password"
              id="password"
              autoComplete="current-password"
              error={touched.password && !!errors.password}
              helperText={touched.password && errors.password}
            />
            <Field name="remember" type="checkbox">
              {({ field }: any) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={field.value}
                      {...field}
                    />
                  }
                  label={<FormattedMessage id="signIn.rememberMe" />}
                />
              )}
            </Field>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isSubmitting}
            >
              <FormattedMessage id="signIn.submitButton" />
            </Button>
          </Form>
        )}
      </Formik>
      <Grid container sx={{ display: "flex", justifyContent: "space-between" }}>
        <Grid item>
          <Link
            to="/auth/forgot-password"
            style={{ color: "#1976d2", fontSize: "14px" }}
          >
            <FormattedMessage id="signIn.forgotPassword" />
          </Link>
        </Grid>
        <Grid item>
          <Link
            to="/auth/signup"
            style={{ color: "#1976d2", fontSize: "14px" }}
          >
            <FormattedMessage id="signIn.signUpLink" />
          </Link>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{ mt: 3, mb: 2, display: "flex", justifyContent: "center" }}
      >
        <Grid item>
          <GoogleOAuthConfig>
            <GoogleLogin
              onSuccess={handleSuccessGoogle}
              onError={handleFailure}
            />
          </GoogleOAuthConfig>
        </Grid>
        <Grid item sx={{ ml: 2 }}>
          <AppleSignInButton
            authOptions={{
              clientId: String(process.env.REACT_APP_APPLE_CLIENT_ID),
              scope: "email name",
              redirectURI: String(process.env.REACT_APP_APPLE_REDIRECT_URL),
              state: "state",
              nonce: "nonce",
              usePopup: true,
            }}
            uiType="dark"
            className="apple-auth-btn"
            noDefaultStyle={false}
            buttonExtraChildren={
              <FormattedMessage id="signIn.continueWithApple" />
            }
            onSuccess={handleAppleCallback}
            onError={(error: any) =>
              setServerError(
                error.message ||
                  intl.formatMessage({ id: "signIn.appleAuthFailedMessage" })
              )
            }
            skipScript={false}
          />
        </Grid>
      </Grid>
      <Copyright sx={{ mt: 5 }} />
    </Box>
  );
}
