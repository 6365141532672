import React, { useEffect } from "react";
import { useEditor, EditorContent, Editor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Image from "@tiptap/extension-image";
import Link from "@tiptap/extension-link";
import TextStyle from "@tiptap/extension-text-style";
import Color from "@tiptap/extension-color";
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  ButtonGroup,
  Tooltip,
  Menu,
  MenuItem,
} from "@mui/material";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import CodeIcon from "@mui/icons-material/Code";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import ImageIcon from "@mui/icons-material/Image";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import { ArrowDropDown } from "@mui/icons-material";

const StyledEditorWrapper = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  overflow: "hidden",
  "& .ProseMirror": {
    padding: theme.spacing(2),
    minHeight: "200px",
    "&:focus": {
      outline: "none",
    },
  },
}));

const StyledMenuBar = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: theme.spacing(1),
  padding: theme.spacing(1),
  borderBottom: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
}));

const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  "& .MuiButton-root": {
    minWidth: "40px",
    padding: theme.spacing(1),
  },
}));

interface EditorProps {
  content: string;
  onChange: (content: string) => void;
  placeholder?: string;
}

type Level = 1 | 2 | 3 | 4 | 5 | 6;

interface MenuBarProps {
  editor: Editor | null;
}

const MenuBar: React.FC<MenuBarProps> = ({ editor }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openHeadingMenu = Boolean(anchorEl);
  const [anchorColorEl, setAnchorColorEl] = React.useState<null | HTMLElement>(
    null
  );
  const openColorMenu = Boolean(anchorColorEl);

  const handleHeadingMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleHeadingMenuClose = () => {
    setAnchorEl(null);
  };

  const handleColorMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorColorEl(event.currentTarget);
  };

  const handleColorMenuClose = () => {
    setAnchorColorEl(null);
  };

  const colors = [
    { name: "Black", value: "#000000" },
    { name: "Red", value: "#FF0000" },
    { name: "Blue", value: "#0000FF" },
    { name: "Green", value: "#008000" },
    { name: "Orange", value: "#FFA500" },
    { name: "Purple", value: "#800080" },
    { name: "Gray", value: "#808080" },
  ];

  if (!editor) {
    return null;
  }

  const headingLevels: Level[] = [1, 2, 3, 4, 5, 6];

  return (
    <StyledMenuBar>
      <StyledButtonGroup>
        <StyledButtonGroup variant="outlined" aria-label="headings">
          <Tooltip title="Headings">
            <Button
              onClick={handleHeadingMenuClick}
              endIcon={<ArrowDropDown />}
            >
              H
            </Button>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            open={openHeadingMenu}
            onClose={handleHeadingMenuClose}
          >
            {headingLevels.map((level: Level) => (
              <MenuItem
                key={level}
                onClick={() => {
                  editor.chain().focus().toggleHeading({ level }).run();
                  handleHeadingMenuClose();
                }}
                selected={editor.isActive("heading", { level })}
              >
                Heading {level}
              </MenuItem>
            ))}
            <MenuItem
              onClick={() => {
                editor.chain().focus().setParagraph().run();
                handleHeadingMenuClose();
              }}
              selected={editor.isActive("paragraph")}
            >
              Paragraph
            </MenuItem>
          </Menu>
        </StyledButtonGroup>

        <StyledButtonGroup variant="outlined" aria-label="text color">
          <Menu
            anchorEl={anchorColorEl}
            open={openColorMenu}
            onClose={handleColorMenuClose}
          >
            {colors.map((color) => (
              <MenuItem
                key={color.value}
                onClick={() => {
                  editor.chain().focus().setColor(color.value).run();
                  handleColorMenuClose();
                }}
                selected={
                  editor.getAttributes("textStyle").color === color.value
                }
              >
                <Box
                  sx={{
                    width: 20,
                    height: 20,
                    backgroundColor: color.value,
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    mr: 1,
                  }}
                />
                {color.name}
              </MenuItem>
            ))}
          </Menu>
          <Tooltip title="Text Color">
            <Button onClick={handleColorMenuClick} endIcon={<ArrowDropDown />}>
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  backgroundColor:
                    editor.getAttributes("textStyle").color || "#000",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
              />
            </Button>
          </Tooltip>
        </StyledButtonGroup>
      </StyledButtonGroup>

      <StyledButtonGroup variant="outlined" aria-label="text formatting">
        <Tooltip title="Bold">
          <Button
            onClick={() => editor.chain().focus().toggleBold().run()}
            variant={editor.isActive("bold") ? "contained" : "outlined"}
          >
            <FormatBoldIcon />
          </Button>
        </Tooltip>
        <Tooltip title="Italic">
          <Button
            onClick={() => editor.chain().focus().toggleItalic().run()}
            variant={editor.isActive("italic") ? "contained" : "outlined"}
          >
            <FormatItalicIcon />
          </Button>
        </Tooltip>
        <Tooltip title="Underline">
          <Button
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            variant={editor.isActive("underline") ? "contained" : "outlined"}
          >
            <FormatUnderlinedIcon />
          </Button>
        </Tooltip>
      </StyledButtonGroup>

      <StyledButtonGroup variant="outlined" aria-label="text alignment">
        <Tooltip title="Align Left">
          <Button
            onClick={() => editor.chain().focus().setTextAlign("left").run()}
            variant={
              editor.isActive({ textAlign: "left" }) ? "contained" : "outlined"
            }
          >
            <FormatAlignLeftIcon />
          </Button>
        </Tooltip>
        <Tooltip title="Align Center">
          <Button
            onClick={() => editor.chain().focus().setTextAlign("center").run()}
            variant={
              editor.isActive({ textAlign: "center" })
                ? "contained"
                : "outlined"
            }
          >
            <FormatAlignCenterIcon />
          </Button>
        </Tooltip>
        <Tooltip title="Align Right">
          <Button
            onClick={() => editor.chain().focus().setTextAlign("right").run()}
            variant={
              editor.isActive({ textAlign: "right" }) ? "contained" : "outlined"
            }
          >
            <FormatAlignRightIcon />
          </Button>
        </Tooltip>
      </StyledButtonGroup>

      <StyledButtonGroup variant="outlined" aria-label="lists">
        <Tooltip title="Bullet List">
          <Button
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            variant={editor.isActive("bulletList") ? "contained" : "outlined"}
          >
            <FormatListBulletedIcon />
          </Button>
        </Tooltip>
        <Tooltip title="Ordered List">
          <Button
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            variant={editor.isActive("orderedList") ? "contained" : "outlined"}
          >
            <FormatListNumberedIcon />
          </Button>
        </Tooltip>
      </StyledButtonGroup>

      <StyledButtonGroup variant="outlined" aria-label="insert">
        <Tooltip title="Insert Link">
          <Button
            onClick={() => {
              const url = prompt("Enter URL");
              if (url) {
                editor
                  .chain()
                  .focus()
                  .extendMarkRange("link")
                  .setLink({ href: url })
                  .run();
              }
            }}
            variant={editor.isActive("link") ? "contained" : "outlined"}
          >
            <InsertLinkIcon />
          </Button>
        </Tooltip>
        <Tooltip title="Insert Image">
          <Button
            onClick={() => {
              const url = prompt("Enter image URL");
              if (url) {
                editor.chain().focus().setImage({ src: url }).run();
              }
            }}
          >
            <ImageIcon />
          </Button>
        </Tooltip>
      </StyledButtonGroup>

      <StyledButtonGroup variant="outlined" aria-label="misc">
        <Tooltip title="Blockquote">
          <Button
            onClick={() => editor.chain().focus().toggleBlockquote().run()}
            variant={editor.isActive("blockquote") ? "contained" : "outlined"}
          >
            <FormatQuoteIcon />
          </Button>
        </Tooltip>
        <Tooltip title="Code Block">
          <Button
            onClick={() => editor.chain().focus().toggleCodeBlock().run()}
            variant={editor.isActive("codeBlock") ? "contained" : "outlined"}
          >
            <CodeIcon />
          </Button>
        </Tooltip>
      </StyledButtonGroup>

      <StyledButtonGroup variant="outlined" aria-label="undo-redo">
        <Tooltip title="Undo">
          <Button
            onClick={() => editor.chain().focus().undo().run()}
            disabled={!editor.can().undo()}
          >
            <UndoIcon />
          </Button>
        </Tooltip>
        <Tooltip title="Redo">
          <Button
            onClick={() => editor.chain().focus().redo().run()}
            disabled={!editor.can().redo()}
          >
            <RedoIcon />
          </Button>
        </Tooltip>
      </StyledButtonGroup>
    </StyledMenuBar>
  );
};

const StyledEditor: React.FC<EditorProps> = ({
  content,
  onChange,
  placeholder,
}) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
      Image,
      Link,
      TextStyle,
      Color,
    ],
    content: content,
    onUpdate: ({ editor }) => {
      onChange(editor.getHTML());
    },
    editorProps: {
      attributes: {
        class: "ProseMirror",
      },
    },
  });

  useEffect(() => {
    if (editor && !editor.isDestroyed) {
      if (editor.getHTML() !== content) {
        editor.commands.setContent(content, false);
      }
    }
  }, [content, editor]);

  return (
    <StyledEditorWrapper>
      <MenuBar editor={editor} />
      <EditorContent editor={editor} />
    </StyledEditorWrapper>
  );
};

export default StyledEditor;
