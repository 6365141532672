import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import {
  Typography,
  Box,
  Button,
  Paper,
  Container,
  CircularProgress,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { AppDispatch, RootState } from "../../redux/store";
import { fetchClassPublic } from "../../redux/enrollment/enrollmentOperations";
import DOMPurify from "dompurify";
import { useLanguage } from "../../components/LanguageProvider/LanguageProvider";
import LanguageSelector from "../../components/LanguageSelector/LanguageSelector";

const getLocalizedValue = (
  obj: Record<string, string>,
  language: string
): string => {
  if (obj[language]) {
    return obj[language];
  }
  return obj["en"] || "";
};

const EnrollPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const classId = searchParams.get("classID");
  const { language, setLanguage } = useLanguage();

  const { user } = useSelector((state: RootState) => state.auth);
  const classData = useSelector((state: RootState) => state.enrollment.class);
  const loading = useSelector((state: RootState) => state.enrollment.loading);

  useEffect(() => {
    const langParam = searchParams.get("Lang");
    if (langParam && ["en", "fr", "uk"].includes(langParam.toLowerCase())) {
      setLanguage(langParam.toLowerCase() as "en" | "fr" | "uk");
    }

    if (classId) {
      dispatch(fetchClassPublic(Number(classId)));
    }
  }, [dispatch, classId, searchParams, setLanguage]);

  useEffect(() => {
    if (user.id) {
      navigate(`/enroll/details${location.search}`);
    }
  }, [user.id, navigate, location.search]);

  const handleAuthRedirect = () => {
    navigate("/auth/signin", {
      state: { from: location.pathname + location.search },
    });
  };

  if (user.id) {
    return null;
  }

  const sanitizedDescription = classData?.description
    ? DOMPurify.sanitize(getLocalizedValue(classData.description, language))
    : "";

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 4, mt: 8, textAlign: "center" }}>
        {loading ? (
          <CircularProgress
            aria-label={intl.formatMessage({ id: "common.loading" })}
          />
        ) : (
          <>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
              <LanguageSelector />
            </Box>
            <Typography
              variant="h4"
              gutterBottom
              sx={{ fontWeight: "bold", color: "primary.main", mb: 3 }}
            >
              <FormattedMessage
                id="enroll.title"
                defaultMessage="Enroll in {className} Class"
                values={{
                  className: classData?.title
                    ? getLocalizedValue(classData.title, language)
                    : "",
                }}
              />
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              <FormattedMessage
                id="enroll.loginRequired"
                defaultMessage="To enroll in this class, you need to be logged in."
              />
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAuthRedirect}
              size="large"
              sx={{
                minWidth: 200,
                fontWeight: "bold",
                textTransform: "none",
                boxShadow: 2,
                "&:hover": {
                  boxShadow: 4,
                },
                mb: 4,
              }}
            >
              <FormattedMessage
                id="enroll.loginButton"
                defaultMessage="Log In"
              />
            </Button>
            {sanitizedDescription && (
              <Box sx={{ mt: 4, textAlign: "left" }}>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{ fontWeight: "bold" }}
                >
                  <FormattedMessage
                    id="enroll.classDescription"
                    defaultMessage="Class Description"
                  />
                </Typography>
                <Box
                  dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
                  sx={{
                    "& p": { mb: 2 },
                    "& ul, & ol": { mb: 2, pl: 4 },
                    "& li": { mb: 1 },
                  }}
                />
              </Box>
            )}
            {classId && (
              <Box
                sx={{
                  mt: 4,
                  p: 2,
                  bgcolor: "background.default",
                  borderRadius: 1,
                  border: 1,
                  borderColor: "divider",
                }}
              >
                <Typography variant="body2" sx={{ fontStyle: "italic" }}>
                  <FormattedMessage
                    id="enroll.classInfo"
                    defaultMessage="You are attempting to enroll in {title} class"
                    values={{
                      title: classData?.title
                        ? getLocalizedValue(classData.title, language)
                        : "",
                    }}
                  />
                </Typography>
              </Box>
            )}
          </>
        )}
      </Paper>
    </Container>
  );
};

export default EnrollPage;
