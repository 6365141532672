import React from "react";
import { useIntl } from "react-intl";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import { Schedule } from "../../../../redux/types/types";

interface ScheduleListProps {
  schedules: Schedule[];
}

const ScheduleList: React.FC<ScheduleListProps> = ({ schedules }) => {
  const intl = useIntl();

  const daysOfWeek = [
    intl.formatMessage({ id: "admin.dashboard.monday" }),
    intl.formatMessage({ id: "admin.dashboard.tuesday" }),
    intl.formatMessage({ id: "admin.dashboard.wednesday" }),
    intl.formatMessage({ id: "admin.dashboard.thursday" }),
    intl.formatMessage({ id: "admin.dashboard.friday" }),
    intl.formatMessage({ id: "admin.dashboard.saturday" }),
    intl.formatMessage({ id: "admin.dashboard.sunday" }),
  ];

  const repeatWeekly = schedules.length > 0 ? schedules[0].repeatWeekly : false;

  const sortedSchedules = [...schedules].sort((a, b) => {
    if (a.dayOfWeek !== b.dayOfWeek) {
      return a.dayOfWeek - b.dayOfWeek;
    }
    return a.startTime.localeCompare(b.startTime);
  });

  const groupedSchedules = sortedSchedules.reduce((acc, schedule) => {
    if (!acc[schedule.dayOfWeek]) {
      acc[schedule.dayOfWeek] = [];
    }
    acc[schedule.dayOfWeek].push(schedule);
    return acc;
  }, {} as Record<number, Schedule[]>);

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6" gutterBottom>
        <FormattedMessage id="admin.dashboard.schedules" />
      </Typography>
      <Typography variant="body2" gutterBottom>
        <FormattedMessage id="admin.dashboard.repeatWeekly" />:{" "}
        {repeatWeekly ? (
          <FormattedMessage id="common.yes" />
        ) : (
          <FormattedMessage id="common.no" />
        )}
      </Typography>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage id="admin.dashboard.dayOfWeek" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="admin.dashboard.startTime" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="admin.dashboard.endTime" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(groupedSchedules).map(
              ([dayOfWeek, daySchedules]) => (
                <React.Fragment key={dayOfWeek}>
                  {daySchedules.map((schedule, index) => (
                    <TableRow key={schedule.id}>
                      {index === 0 && (
                        <TableCell rowSpan={daySchedules.length}>
                          {daysOfWeek[parseInt(dayOfWeek)]}
                        </TableCell>
                      )}
                      <TableCell>{schedule.startTime}</TableCell>
                      <TableCell>{schedule.endTime}</TableCell>
                    </TableRow>
                  ))}
                </React.Fragment>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ScheduleList;
