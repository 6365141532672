import React from "react";
import { Box, Typography, Paper, TextField, Grid, Alert } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { FormattedMessage, useIntl } from "react-intl";
import { useUserManagement } from "./useUserManagement";
import UsersList from "./UsersList/UsersList";
import DeleteUserDialog from "./DeleteUserDialog/DeleteUserDialog";
import EditUserDialog from "./EditUserDialog/EditUserDialog";

export const UserManagement: React.FC = () => {
  const intl = useIntl();
  const {
    users,
    totalUsers,
    error,
    deleteDialogOpen,
    editDialogOpen,
    userToEdit,
    page,
    rowsPerPage,
    orderBy,
    order,
    searchTerm,
    fetchUserData,
    handleDeleteUser,
    confirmDeleteUser,
    handleEditUser,
    handleUpdateUser,
    handleResetPassword,
    handleChangePage,
    handleChangeRowsPerPage,
    handleRequestSort,
    handleSearchChange,
    setDeleteDialogOpen,
    setEditDialogOpen,
    setUserToEdit,
  } = useUserManagement();

  React.useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  return (
    <Box>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Box sx={{ p: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" component="div">
                <FormattedMessage id="admin.dashboard.users" />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <SearchIcon sx={{ mr: 1 }} />
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder={intl.formatMessage({
                    id: "admin.dashboard.searchPlaceholder",
                  })}
                  value={searchTerm}
                  onChange={handleSearchChange}
                  fullWidth
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <UsersList
          users={users}
          totalUsers={totalUsers}
          page={page}
          rowsPerPage={rowsPerPage}
          orderBy={orderBy}
          order={order}
          onRequestSort={handleRequestSort}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onEditUser={handleEditUser}
          onDeleteUser={handleDeleteUser}
          onResetPassword={handleResetPassword}
        />
      </Paper>
      <DeleteUserDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={confirmDeleteUser}
      />
      <EditUserDialog
        open={editDialogOpen}
        userToEdit={userToEdit}
        onClose={() => setEditDialogOpen(false)}
        onSave={handleUpdateUser}
        onUserChange={setUserToEdit}
      />
    </Box>
  );
};
