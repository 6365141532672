import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  fetchClasses,
  deleteClass,
  updateClass,
  createClass,
} from "../../../redux/admin/adminOperations";
import { Class } from "../../../redux/types/types";

type Order = "asc" | "desc";

export const useClassManagement = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { classes, totalClasses, loading, error } = useSelector(
    (state: RootState) => state.admin
  );

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [classToDelete, setClassToDelete] = useState<number | null>(null);
  const [classToEdit, setClassToEdit] = useState<Class | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [orderBy, setOrderBy] = useState<keyof Class>("id");
  const [order, setOrder] = useState<Order>("asc");
  const [searchTerm, setSearchTerm] = useState("");

  const fetchClassData = useCallback(() => {
    dispatch(
      fetchClasses({
        page: page + 1,
        limit: rowsPerPage,
        sortBy: orderBy,
        sortOrder: order,
        search: searchTerm,
      })
    );
  }, [dispatch, page, rowsPerPage, orderBy, order, searchTerm]);

  const handleDeleteClass = useCallback((classId: number) => {
    setClassToDelete(classId);
    setDeleteDialogOpen(true);
  }, []);

  const confirmDeleteClass = useCallback(() => {
    if (classToDelete) {
      dispatch(deleteClass(classToDelete))
        .unwrap()
        .then(() => {
          fetchClassData();
          setDeleteDialogOpen(false);
          setClassToDelete(null);
        })
        .catch((error) => {
          console.error("Failed to delete class:", error);
        });
    }
  }, [classToDelete, dispatch, fetchClassData]);

  const handleEditClass = useCallback((classItem: Class) => {
    setClassToEdit(classItem);
    setEditDialogOpen(true);
  }, []);

  const handleUpdateClass = useCallback(
    (updatedClass: Class) => {
      if (updatedClass && updatedClass.id) {
        dispatch(
          updateClass({
            classId: updatedClass.id,
            classData: updatedClass,
          })
        )
          .unwrap()
          .then(() => {
            fetchClassData();
            setEditDialogOpen(false);
            setClassToEdit(null);
          })
          .catch((error) => {
            console.error("Failed to update class:", error);
          });
      }
    },
    [dispatch, fetchClassData]
  );

  const handleCreateClass = useCallback(
    (classData: Omit<Class, "id">) => {
      dispatch(createClass(classData))
        .unwrap()
        .then(() => {
          fetchClassData();
          setCreateDialogOpen(false);
        })
        .catch((error) => {
          console.error("Failed to create class:", error);
        });
    },
    [dispatch, fetchClassData]
  );

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    },
    []
  );

  const handleRequestSort = useCallback(
    (property: keyof Class) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    },
    [order, orderBy]
  );

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
      setPage(0);
    },
    []
  );

  return {
    classes,
    totalClasses,
    loading,
    error,
    deleteDialogOpen,
    editDialogOpen,
    createDialogOpen,
    classToDelete,
    classToEdit,
    page,
    rowsPerPage,
    orderBy,
    order,
    searchTerm,
    fetchClassData,
    handleDeleteClass,
    confirmDeleteClass,
    handleEditClass,
    handleUpdateClass,
    handleCreateClass,
    handleChangePage,
    handleChangeRowsPerPage,
    handleRequestSort,
    handleSearchChange,
    setDeleteDialogOpen,
    setEditDialogOpen,
    setCreateDialogOpen,
    setClassToEdit,
  };
};
