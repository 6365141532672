import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "yup-phone";
import {
  TextField,
  Button,
  Typography,
  Box,
  Alert,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Notiflix from "notiflix";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { FormattedMessage, useIntl } from "react-intl";

import { AppDispatch, RootState } from "../../redux/store";
import { logOut, updateProfile } from "../../redux/auth/authOperations";
import { forgotPasswordApi } from "../../utils/api/auth";
import CustomTextField from "../CustomTextField/CustomTextField";

const ProfileSchema = Yup.object().shape({
  firstname: Yup.string()
    .required("First name is required")
    .min(2, "First name must be at least 2 characters")
    .max(50, "First name must not exceed 50 characters"),
  lastname: Yup.string()
    .required("Last name is required")
    .min(2, "Last name must be at least 2 characters")
    .max(50, "Last name must not exceed 50 characters"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  phone: Yup.string().required("Phone number is required"),
});

export default function Profile() {
  const intl = useIntl();
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.auth.user);
  const [initialValues, setInitialValues] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
  });
  const [serverError, setServerError] = useState<string | null>(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (user) {
      setInitialValues({
        firstname: user.firstname || "",
        lastname: user.lastname || "",
        email: user.email || "",
        phone: user.phone || "",
      });
    }
  }, [user]);

  const handleSubmit = async (
    values: {
      firstname: string;
      lastname: string;
      email: string;
      phone: string;
    },
    { setSubmitting }: any
  ) => {
    if (values.email !== user.email) {
      Notiflix.Confirm.show(
        intl.formatMessage({ id: "profile.emailChangeConfirmation" }),
        intl.formatMessage({ id: "profile.emailChangeConfirmationMessage" }),
        intl.formatMessage({ id: "profile.yes" }),
        intl.formatMessage({ id: "profile.no" }),
        async () => {
          try {
            await dispatch(updateProfile(values)).unwrap();
            setServerError(null);
            setSubmitting(false);
            dispatch(logOut());
          } catch (error: any) {
            setServerError(
              error.message || intl.formatMessage({ id: "profile.updateError" })
            );
            setSubmitting(false);
          }
        },
        () => {
          setSubmitting(false);
        }
      );
    } else {
      try {
        await dispatch(updateProfile(values)).unwrap();
        setServerError(null);
        setSubmitting(false);
        Notiflix.Notify.success(
          intl.formatMessage({ id: "profile.updateSuccess" })
        );
      } catch (error: any) {
        setServerError(
          error.message || intl.formatMessage({ id: "profile.updateError" })
        );
        setSubmitting(false);
      }
    }
  };

  const handleChangePassword = async () => {
    Notiflix.Confirm.show(
      intl.formatMessage({ id: "profile.passwordChangeConfirmation" }),
      intl.formatMessage({ id: "profile.passwordChangeConfirmationMessage" }),
      intl.formatMessage({ id: "profile.yes" }),
      intl.formatMessage({ id: "profile.no" }),
      async () => {
        try {
          await forgotPasswordApi(initialValues.email);
          Notiflix.Notify.success(
            intl.formatMessage({ id: "profile.passwordResetEmailSent" })
          );
          dispatch(logOut());
        } catch (error: any) {
          console.error("Error sending password reset email", error);
          Notiflix.Notify.failure(
            error.message ||
              intl.formatMessage({ id: "profile.passwordResetEmailError" })
          );
        }
      }
    );
  };

  return (
    <Box
      sx={{
        my: 4,
        mx: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        maxWidth: "600px",
        margin: "0 auto",
        pt: isMobile ? 5 : 0,
      }}
    >
      {serverError && (
        <Alert severity="error" sx={{ mt: 2, width: "100%" }}>
          {serverError}
        </Alert>
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={ProfileSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ errors, touched, isSubmitting, setFieldValue, values }) => (
          <Form noValidate style={{ width: "100%" }}>
            <Field
              as={TextField}
              margin="normal"
              required
              fullWidth
              id="firstname"
              label={intl.formatMessage({ id: "signUp.firstName" })}
              name="firstname"
              autoComplete="given-name"
              error={touched.firstname && !!errors.firstname}
              helperText={touched.firstname && errors.firstname}
            />
            <Field
              as={TextField}
              margin="normal"
              required
              fullWidth
              id="lastname"
              label={intl.formatMessage({ id: "signUp.lastName" })}
              name="lastname"
              autoComplete="family-name"
              error={touched.lastname && !!errors.lastname}
              helperText={touched.lastname && errors.lastname}
            />
            <Field
              as={TextField}
              margin="normal"
              required
              fullWidth
              id="email"
              label={intl.formatMessage({ id: "signUp.email" })}
              name="email"
              autoComplete="email"
              error={touched.email && !!errors.email}
              helperText={touched.email && errors.email}
            />
            <Box sx={{ my: 2 }}>
              <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry="US"
                value={values.phone}
                onChange={(value) => setFieldValue("phone", value)}
                inputComponent={CustomTextField as any}
              />
              {touched.phone && errors.phone && (
                <Typography color="error" variant="caption">
                  {errors.phone}
                </Typography>
              )}
            </Box>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isSubmitting}
            >
              <FormattedMessage id="profile.updateProfile" />
            </Button>
            <Button
              type="button"
              fullWidth
              variant="outlined"
              sx={{ mt: 1, mb: 2 }}
              onClick={handleChangePassword}
              disabled={Boolean(!initialValues.email.length)}
            >
              <FormattedMessage id="profile.changePassword" />
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
