import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  IconButton,
  TablePagination,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  LockReset as LockResetIcon,
  People as PeopleIcon,
} from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import AttendeesList from "./AttendeesList/AttendeesList";
import { User } from "../../../../redux/types/types";

interface UsersListProps {
  users: User[];
  totalUsers: number;
  page: number;
  rowsPerPage: number;
  orderBy: keyof User | any;
  order: "asc" | "desc";
  onRequestSort: (property: keyof User) => void;
  onChangePage: (event: unknown, newPage: number) => void;
  onChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onEditUser: (user: User) => void;
  onDeleteUser: (userId: number) => void;
  onResetPassword: (userId: number) => void;
}

export default function UsersList({
  users,
  totalUsers,
  page,
  rowsPerPage,
  orderBy,
  order,
  onRequestSort,
  onChangePage,
  onChangeRowsPerPage,
  onEditUser,
  onDeleteUser,
  onResetPassword,
}: UsersListProps) {
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [attendeesDialogOpen, setAttendeesDialogOpen] = useState(false);

  const handleOpenAttendees = (user: User) => {
    setSelectedUser(user);
    setAttendeesDialogOpen(true);
  };

  const handleCloseAttendees = () => {
    setAttendeesDialogOpen(false);
    setSelectedUser(null);
  };

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="user table">
          <TableHead>
            <TableRow>
              {["id", "email", "firstname", "lastname", "phone"].map(
                (column) => (
                  <TableCell key={column}>
                    <TableSortLabel
                      active={orderBy === column}
                      direction={orderBy === column ? order : "asc"}
                      onClick={() => onRequestSort(column as keyof User)}
                    >
                      <FormattedMessage id={`admin.dashboard.${column}`} />
                    </TableSortLabel>
                  </TableCell>
                )
              )}
              <TableCell>
                <FormattedMessage id="admin.dashboard.actions" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user: User) => (
              <TableRow key={user.id}>
                <TableCell>{user.id}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.firstname}</TableCell>
                <TableCell>{user.lastname}</TableCell>
                <TableCell>{user.phone}</TableCell>
                <TableCell>
                  <IconButton
                    color="primary"
                    onClick={() => onEditUser(user)}
                    size="small"
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    onClick={() =>
                      typeof user.id === "number" && onDeleteUser(user.id)
                    }
                    size="small"
                  >
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    color="warning"
                    onClick={() =>
                      typeof user.id === "number" && onResetPassword(user.id)
                    }
                    size="small"
                  >
                    <LockResetIcon />
                  </IconButton>
                  <IconButton
                    color="default"
                    onClick={() => handleOpenAttendees(user)}
                    size="small"
                  >
                    <PeopleIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalUsers}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
        labelRowsPerPage={<FormattedMessage id="admin.dashboard.rowsPerPage" />}
      />
      {selectedUser && (
        <AttendeesList
          attendees={selectedUser.attendees}
          open={attendeesDialogOpen}
          onClose={handleCloseAttendees}
        />
      )}
    </>
  );
}
