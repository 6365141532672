import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  IconButton,
  TablePagination,
  Typography,
  Box,
  Chip,
  Collapse,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import ScheduleList from "./ScheduleList";
import { Class } from "../../../../redux/types/types";

interface ClassesListProps {
  classes: Class[];
  totalClasses: number;
  page: number;
  rowsPerPage: number;
  orderBy: keyof Class | any;
  order: "asc" | "desc";
  onRequestSort: (property: keyof Class) => void;
  onChangePage: (event: unknown, newPage: number) => void;
  onChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onEditClass: (classItem: Class) => void;
  onDeleteClass: (classId: number) => void;
}

const languages = ["en", "uk", "fr"] as const;
type Language = (typeof languages)[number];

const LocalizedContent: React.FC<{ content: Record<Language, string> }> = ({
  content,
}) => (
  <Box sx={{ display: "flex", flexDirection: "column" }}>
    {languages.map(
      (lang) =>
        content[lang] && (
          <Box
            key={lang}
            sx={{ display: "flex", alignItems: "center", mb: 0.5 }}
          >
            <Chip
              label={lang.toUpperCase()}
              size="small"
              color="primary"
              variant="outlined"
              sx={{ width: 40, mr: 1, flexShrink: 0 }}
            />
            <Typography variant="body2" component="span">
              {content[lang]}
            </Typography>
          </Box>
        )
    )}
  </Box>
);

export default function ClassesList({
  classes,
  totalClasses,
  page,
  rowsPerPage,
  orderBy,
  order,
  onRequestSort,
  onChangePage,
  onChangeRowsPerPage,
  onEditClass,
  onDeleteClass,
}: ClassesListProps) {
  const [expandedClass, setExpandedClass] = useState<number | null>(null);

  const createSortHandler =
    (property: keyof Class) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(property);
    };

  const handleExpandClick = (classId: number) => {
    setExpandedClass(expandedClass === classId ? null : classId);
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "title"}
                  direction={orderBy === "title" ? order : "asc"}
                  onClick={createSortHandler("title")}
                >
                  <FormattedMessage id="admin.dashboard.class.title" />
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <FormattedMessage id="admin.dashboard.location" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="admin.dashboard.type" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="admin.dashboard.instructor" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="admin.dashboard.actions" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {classes.map((classItem) => (
              <React.Fragment key={classItem.id}>
                <TableRow>
                  <TableCell>
                    <IconButton
                      onClick={() => handleExpandClick(classItem.id)}
                      size="small"
                    >
                      {expandedClass === classItem.id ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <LocalizedContent content={classItem.title} />
                  </TableCell>
                  <TableCell>
                    <LocalizedContent content={classItem.location.name} />
                  </TableCell>
                  <TableCell>
                    <LocalizedContent content={classItem.type.name} />
                  </TableCell>
                  <TableCell>
                    {`${classItem.instructor.firstname} ${classItem.instructor.lastname}`}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => onEditClass(classItem)}
                      size="small"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() => onDeleteClass(classItem.id)}
                      size="small"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                  >
                    <Collapse
                      in={expandedClass === classItem.id}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box sx={{ margin: 1 }}>
                        <ScheduleList schedules={classItem.schedules} />
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalClasses}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
      />
    </>
  );
}
