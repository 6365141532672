import React, { createContext, useState, useContext, ReactNode } from "react";
import { IntlProvider } from "react-intl";
import enMessages from "../../utils/locales/en.json";
import frMessages from "../../utils/locales/fr.json";
import ukMessages from "../../utils/locales/uk.json";

export type Language = "en" | "fr" | "uk";

interface LanguageContextType {
  language: Language;
  setLanguage: (lang: Language) => void;
}

const LanguageContext = createContext<LanguageContextType | undefined>(
  undefined
);

const messages: Record<Language, Record<string, string>> = {
  en: enMessages,
  fr: frMessages,
  uk: ukMessages,
};

export const LanguageProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [language, setLanguage] = useState<Language>("en");

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      <IntlProvider messages={messages[language]} locale={language}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error("useLanguage must be used within a LanguageProvider");
  }
  return context;
};
