import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { Instructor } from "../../../../redux/types/types";

interface CreateInstructorDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (instructorData: Omit<Instructor, "id" | "role">) => void;
}

export default function CreateInstructorDialog({
  open,
  onClose,
  onSave,
}: CreateInstructorDialogProps) {
  const intl = useIntl();
  const [newInstructor, setNewInstructor] = useState<
    Omit<Instructor, "id" | "role">
  >({
    email: "",
    firstname: "",
    lastname: "",
    phone: "",
  });

  const handleChange =
    (field: keyof Omit<Instructor, "id" | "role">) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setNewInstructor({ ...newInstructor, [field]: e.target.value });
    };

  const handleSave = () => {
    onSave(newInstructor);
    setNewInstructor({
      email: "",
      firstname: "",
      lastname: "",
      phone: "",
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
      disableEscapeKeyDown
      onBackdropClick={(event) => event.stopPropagation()}
    >
      <DialogTitle id="form-dialog-title">
        <FormattedMessage id="admin.dashboard.createInstructor" />
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="email"
          label={intl.formatMessage({ id: "admin.dashboard.email" })}
          type="email"
          fullWidth
          value={newInstructor.email}
          onChange={handleChange("email")}
        />
        <TextField
          margin="dense"
          id="firstname"
          label={intl.formatMessage({ id: "admin.dashboard.firstname" })}
          type="text"
          fullWidth
          value={newInstructor.firstname}
          onChange={handleChange("firstname")}
        />
        <TextField
          margin="dense"
          id="lastname"
          label={intl.formatMessage({ id: "admin.dashboard.lastname" })}
          type="text"
          fullWidth
          value={newInstructor.lastname}
          onChange={handleChange("lastname")}
        />
        <TextField
          margin="dense"
          id="phone"
          label={intl.formatMessage({ id: "admin.dashboard.phone" })}
          type="text"
          fullWidth
          value={newInstructor.phone}
          onChange={handleChange("phone")}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <FormattedMessage id="admin.dashboard.cancel" />
        </Button>
        <Button onClick={handleSave}>
          <FormattedMessage id="admin.dashboard.create" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
