import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

interface Attendee {
  id: number;
  firstName: string;
  lastName: string;
  birthdate: Date;
  sex: string;
  phone: string;
  email: string;
}

interface AttendeesListProps {
  attendees: Attendee[];
  open: boolean;
  onClose: () => void;
}

const calculateAge = (birthdate: Date) => {
  const today = new Date();
  const birthDate = new Date(birthdate);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export default function AttendeesList({
  attendees,
  open,
  onClose,
}: AttendeesListProps) {
  const [isOpen, setIsOpen] = useState(open);
  const intl = useIntl();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      aria-labelledby="attendees-list-dialog-title"
    >
      <DialogTitle id="attendees-list-dialog-title">
        <FormattedMessage id="admin.dashboard.attendeesList" />
      </DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage id="admin.dashboard.firstName" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="admin.dashboard.lastName" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="admin.dashboard.birthdate" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="admin.dashboard.age" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="admin.dashboard.sex" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="admin.dashboard.phone" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="admin.dashboard.email" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {attendees?.map((attendee) => (
                <TableRow key={attendee.id}>
                  <TableCell>{attendee.firstName}</TableCell>
                  <TableCell>{attendee.lastName}</TableCell>
                  <TableCell>
                    {intl.formatDate(attendee.birthdate, {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </TableCell>
                  <TableCell>{calculateAge(attendee.birthdate)}</TableCell>
                  <TableCell>{attendee.sex}</TableCell>
                  <TableCell>{attendee.phone}</TableCell>
                  <TableCell>{attendee.email}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          <FormattedMessage id="admin.dashboard.close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
