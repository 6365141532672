import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CssBaseline,
  Tooltip,
  useMediaQuery,
  Select,
  MenuItem,
  useTheme,
} from "@mui/material";
import {
  ExitToApp as ExitToAppIcon,
  Refresh as RefreshIcon,
  People as PeopleIcon,
  School as SchoolIcon,
  LocationOn as LocationIcon,
  Category as CategoryIcon,
  Menu as MenuIcon,
  Class as ClassIcon,
} from "@mui/icons-material";
import { AppDispatch, RootState } from "../../redux/store";
import { logOut } from "../../redux/auth/authOperations";
import { FormattedMessage, useIntl } from "react-intl";
import { useLanguage } from "../LanguageProvider/LanguageProvider";
import { UserManagement } from "./UserManagement/UserManagement";
import { InstructorManagement } from "./InstructorManagement/InstructorManagement";
import { LocationManagement } from "./LocationManagement/LocationManagement";
import { TypeManagement } from "./TypeManagement/TypeManagement";
import { ClassManagement } from "./ClassManagement/ClassManagement";
import {
  fetchUsers,
  fetchInstructors,
  fetchLocations,
  fetchTypes,
  fetchClasses,
} from "../../redux/admin/adminOperations";

const drawerWidth = 240;

export default function AdminDashboard() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const isAdmin = useSelector((state: RootState) => state.auth.isAdmin);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { language, setLanguage } = useLanguage();
  const intl = useIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const [selectedTab, setSelectedTab] = useState<
    "users" | "instructors" | "locations" | "types" | "classes"
  >("users");

  React.useEffect(() => {
    if (!isAdmin) {
      navigate("/admin/signin");
    }
  }, [isAdmin, navigate]);

  const handleLogout = () => {
    dispatch(logOut());
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleRefresh = async () => {
    switch (selectedTab) {
      case "users":
        await dispatch(
          fetchUsers({
            page: 1,
            limit: 5,
            sortBy: "id",
            sortOrder: "asc",
            search: "",
          })
        );
        break;
      case "instructors":
        await dispatch(
          fetchInstructors({
            page: 1,
            limit: 5,
            sortBy: "id",
            sortOrder: "asc",
            search: "",
          })
        );
        break;
      case "locations":
        await dispatch(
          fetchLocations({
            page: 1,
            limit: 5,
            sortBy: "id",
            sortOrder: "asc",
            search: "",
          })
        );
        break;
      case "types":
        await dispatch(
          fetchTypes({
            page: 1,
            limit: 5,
            sortBy: "id",
            sortOrder: "asc",
            search: "",
          })
        );
        break;
      case "classes":
        await dispatch(
          fetchClasses({
            page: 1,
            limit: 5,
            sortBy: "id",
            sortOrder: "asc",
            search: "",
          })
        );
        break;
      default:
        console.error("Unknown tab selected");
    }
  };

  const drawer = (
    <Box sx={{ overflow: "auto" }}>
      <List>
        <ListItem
          button
          selected={selectedTab === "users"}
          onClick={() => setSelectedTab("users")}
          sx={{
            backgroundColor:
              selectedTab === "users" ? "rgba(0, 0, 0, 0.08)" : "inherit",
          }}
        >
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText
            primary={<FormattedMessage id="admin.dashboard.users" />}
          />
        </ListItem>
        <ListItem
          button
          selected={selectedTab === "instructors"}
          onClick={() => setSelectedTab("instructors")}
          sx={{
            backgroundColor:
              selectedTab === "instructors" ? "rgba(0, 0, 0, 0.08)" : "inherit",
          }}
        >
          <ListItemIcon>
            <SchoolIcon />
          </ListItemIcon>
          <ListItemText
            primary={<FormattedMessage id="admin.dashboard.instructors" />}
          />
        </ListItem>
        <ListItem
          button
          selected={selectedTab === "locations"}
          onClick={() => setSelectedTab("locations")}
          sx={{
            backgroundColor:
              selectedTab === "locations" ? "rgba(0, 0, 0, 0.08)" : "inherit",
          }}
        >
          <ListItemIcon>
            <LocationIcon />
          </ListItemIcon>
          <ListItemText
            primary={<FormattedMessage id="admin.dashboard.locations" />}
          />
        </ListItem>
        <ListItem
          button
          selected={selectedTab === "types"}
          onClick={() => setSelectedTab("types")}
          sx={{
            backgroundColor:
              selectedTab === "types" ? "rgba(0, 0, 0, 0.08)" : "inherit",
          }}
        >
          <ListItemIcon>
            <CategoryIcon />
          </ListItemIcon>
          <ListItemText
            primary={<FormattedMessage id="admin.dashboard.types" />}
          />
        </ListItem>
        <ListItem
          button
          selected={selectedTab === "classes"}
          onClick={() => setSelectedTab("classes")}
          sx={{
            backgroundColor:
              selectedTab === "classes" ? "rgba(0, 0, 0, 0.08)" : "inherit",
          }}
        >
          <ListItemIcon>
            <ClassIcon />
          </ListItemIcon>
          <ListItemText
            primary={<FormattedMessage id="admin.dashboard.classes" />}
          />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "#2196f3",
        }}
      >
        <Toolbar>
          {(isMobile || isTablet) && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={toggleDrawer}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            <FormattedMessage id="admin.dashboard.title" />
          </Typography>
          <Select
            value={language}
            onChange={(e) => setLanguage(e.target.value as "en" | "fr" | "uk")}
            size="small"
            sx={{
              mr: 2,
              color: "white",
              "& .MuiSelect-icon": { color: "white" },
            }}
          >
            <MenuItem value="en">EN</MenuItem>
            <MenuItem value="fr">FR</MenuItem>
            <MenuItem value="uk">UK</MenuItem>
          </Select>
          <Tooltip
            title={intl.formatMessage({ id: "admin.dashboard.refresh" })}
          >
            <span>
              <IconButton
                color="inherit"
                disabled={false}
                aria-label="refresh"
                onClick={() => {
                  handleRefresh();
                }}
              >
                <RefreshIcon />
              </IconButton>
            </span>
          </Tooltip>
          <IconButton
            color="inherit"
            onClick={handleLogout}
            aria-label="logout"
          >
            <ExitToAppIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {!isMobile && !isTablet && (
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
        >
          <Toolbar />
          {drawer}
        </Drawer>
      )}
      {(isMobile || isTablet) && (
        <Drawer
          variant="temporary"
          open={drawerOpen}
          onClose={toggleDrawer}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          <Toolbar />
          {drawer}
        </Drawer>
      )}
      <Box component="main" sx={{ flexGrow: 1, p: 3, width: "100%" }}>
        <Toolbar />
        {selectedTab === "users" && <UserManagement />}
        {selectedTab === "instructors" && <InstructorManagement />}
        {selectedTab === "locations" && <LocationManagement />}
        {selectedTab === "types" && <TypeManagement />}
        {selectedTab === "classes" && <ClassManagement />}
      </Box>
    </Box>
  );
}
