import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import {
  fetchSchedules,
  bulkCreateSchedules,
  bulkUpdateSchedules,
  bulkDeleteSchedules,
} from "../../../../redux/admin/adminOperations";
import {
  Box,
  Typography,
  Checkbox,
  IconButton,
  FormControlLabel,
  Button,
  Paper,
  Grid,
  Toolbar,
  AppBar,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { FormattedMessage, useIntl } from "react-intl";
import { useClassManagement } from "../useClassManagement";
import { Schedule } from "../../../../redux/types/types";

interface LocalSchedule extends Schedule {
  isNew?: boolean;
  isEditing?: boolean;
  isDirty?: boolean;
  isDeleted?: boolean;
}

interface ScheduleManagerProps {
  classId: number;
}

const daysOfWeek = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

export default function ScheduleManager({ classId }: ScheduleManagerProps) {
  const dispatch = useDispatch<AppDispatch>();
  const [localSchedules, setLocalSchedules] = useState<LocalSchedule[]>([]);
  const [repeatWeekly, setRepeatWeekly] = useState(false);
  const intl = useIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { fetchClassData } = useClassManagement();

  useEffect(() => {
    loadSchedules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, classId, intl]);

  const loadSchedules = async () => {
    try {
      const fetchedSchedules = await dispatch(
        fetchSchedules({
          classId,
          page: 1,
          limit: 100,
          sortBy: "dayOfWeek",
          sortOrder: "asc",
        })
      ).unwrap();

      setLocalSchedules(
        fetchedSchedules.schedules.map((schedule) => ({
          ...schedule,
          isDirty: false,
          isEditing: false,
          isDeleted: false,
        }))
      );
      setRepeatWeekly(fetchedSchedules.schedules.some((s) => s.repeatWeekly));
      Notify.success(
        intl.formatMessage({ id: "admin.dashboard.schedulesLoadedSuccess" })
      );
    } catch (error) {
      console.error("Failed to fetch schedules:", error);
      Notify.failure(
        intl.formatMessage({ id: "admin.dashboard.schedulesLoadedError" })
      );
    }
  };

  const handleAddSchedule = (dayOfWeek: number) => {
    const newSchedule: LocalSchedule = {
      id: Date.now(),
      dayOfWeek,
      startTime: "09:00",
      endTime: "17:00",
      repeatWeekly,
      classId,
      isNew: true,
      isEditing: true,
      isDirty: true,
      isDeleted: false,
    };
    setLocalSchedules([...localSchedules, newSchedule]);
  };

  const handleUpdateSchedule = (updatedSchedule: LocalSchedule) => {
    const updatedSchedules = localSchedules.map((s) =>
      s.id === updatedSchedule.id ? { ...updatedSchedule, isDirty: true } : s
    );
    setLocalSchedules(updatedSchedules);
  };

  const handleDeleteSchedule = (scheduleId: number) => {
    const updatedSchedules = localSchedules.map((s) =>
      s.id === scheduleId ? { ...s, isDeleted: true, isDirty: true } : s
    );
    setLocalSchedules(updatedSchedules);
  };

  const handleSaveAllChanges = async () => {
    const schedulesToSave = localSchedules.filter((s) => !s.isDeleted);
    const schedulesToCreate = schedulesToSave.filter((s) => s.isNew);
    const schedulesToUpdate = schedulesToSave.filter(
      (s) => s.isDirty && !s.isNew
    );
    const schedulesToDelete = localSchedules.filter((s) => s.isDeleted);

    try {
      if (schedulesToDelete.length > 0) {
        await dispatch(
          bulkDeleteSchedules(schedulesToDelete.map((s) => s.id))
        ).unwrap();
      }
      if (schedulesToUpdate.length > 0) {
        await dispatch(bulkUpdateSchedules(schedulesToUpdate)).unwrap();
      }
      if (schedulesToCreate.length > 0) {
        await dispatch(bulkCreateSchedules(schedulesToCreate)).unwrap();
      }

      Notify.success(
        intl.formatMessage({ id: "admin.dashboard.schedulesSavedSuccess" })
      );
      loadSchedules();
      fetchClassData();
    } catch (error) {
      console.error("Failed to save schedules:", error);
      Notify.failure(
        intl.formatMessage({ id: "admin.dashboard.schedulesSavedError" })
      );
    }
  };

  const handleRepeatWeeklyChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newRepeatWeekly = event.target.checked;
    setRepeatWeekly(newRepeatWeekly);
    const updatedSchedules = localSchedules.map((s) => ({
      ...s,
      repeatWeekly: newRepeatWeekly,
      isDirty: s.repeatWeekly !== newRepeatWeekly,
    }));
    setLocalSchedules(updatedSchedules);
  };

  const handleTimeChange = (
    schedule: LocalSchedule,
    field: "startTime" | "endTime",
    value: string
  ) => {
    const updatedSchedule = {
      ...schedule,
      [field]: value,
      isEditing: false,
    };
    handleUpdateSchedule(updatedSchedule);
  };

  return (
    <Box sx={{ flexGrow: 1, position: "relative", height: "100%" }}>
      <AppBar position="static" color="default">
        <Toolbar
          sx={{
            flexDirection: isMobile ? "column" : "row",
            alignItems: "flex-start",
            py: 2,
          }}
        >
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, mb: isMobile ? 2 : 0 }}
          >
            <FormattedMessage id="admin.dashboard.scheduleManager" />
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={repeatWeekly}
                onChange={handleRepeatWeeklyChange}
                name="repeatWeekly"
              />
            }
            label={<FormattedMessage id="admin.dashboard.repeatWeekly" />}
          />
        </Toolbar>
      </AppBar>
      <Box sx={{ height: "calc(100% - 64px)", overflowY: "auto", pb: 8 }}>
        <Paper sx={{ p: 2, m: 2 }}>
          <Grid container spacing={2}>
            {daysOfWeek.map((day, index) => (
              <Grid item xs={12} key={day}>
                <Paper sx={{ p: 2 }}>
                  <Box
                    display="flex"
                    flexDirection={isMobile ? "column" : "row"}
                    justifyContent="space-between"
                    alignItems={isMobile ? "flex-start" : "center"}
                    mb={2}
                  >
                    <Typography variant="h6" sx={{ mb: isMobile ? 1 : 0 }}>
                      <FormattedMessage id={`admin.dashboard.${day}`} />
                    </Typography>
                    <IconButton
                      onClick={() => handleAddSchedule(index)}
                      color="primary"
                      sx={{ alignSelf: isMobile ? "flex-end" : "center" }}
                    >
                      <AddIcon />
                    </IconButton>
                  </Box>
                  {localSchedules
                    .filter((s) => s.dayOfWeek === index && !s.isDeleted)
                    .map((schedule) => (
                      <Box key={schedule.id} sx={{ mb: 2 }}>
                        <Box
                          display="flex"
                          flexDirection={isMobile ? "column" : "row"}
                          alignItems="center"
                        >
                          <TextField
                            label={intl.formatMessage({
                              id: "admin.dashboard.startTime",
                            })}
                            type="time"
                            value={schedule.startTime}
                            onChange={(e) =>
                              handleTimeChange(
                                schedule,
                                "startTime",
                                e.target.value
                              )
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              step: 300,
                            }}
                            sx={{
                              mr: 2,
                              mb: isMobile ? 1 : 0,
                              width: isMobile ? "100%" : "auto",
                            }}
                          />
                          <TextField
                            label={intl.formatMessage({
                              id: "admin.dashboard.endTime",
                            })}
                            type="time"
                            value={schedule.endTime}
                            onChange={(e) =>
                              handleTimeChange(
                                schedule,
                                "endTime",
                                e.target.value
                              )
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              step: 300,
                            }}
                            sx={{
                              mr: 2,
                              mb: isMobile ? 1 : 0,
                              width: isMobile ? "100%" : "auto",
                            }}
                          />
                          <IconButton
                            onClick={() => handleDeleteSchedule(schedule.id)}
                            color="error"
                            sx={{ alignSelf: isMobile ? "flex-end" : "center" }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    ))}
                  {localSchedules.filter(
                    (s) => s.dayOfWeek === index && !s.isDeleted
                  ).length === 0 && (
                    <Typography color="textSecondary">
                      <FormattedMessage id="admin.dashboard.unavailable" />
                    </Typography>
                  )}
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Box>
      <Box
        sx={{
          position: "sticky",
          bottom: -20,
          left: 0,
          right: 0,
          padding: 2,
          backgroundColor: "white",
          borderTop: 1,
          borderColor: "divider",
          display: "flex",
          justifyContent: "flex-end",
          zIndex: 9999,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveAllChanges}
          sx={{
            minWidth: isMobile ? "100%" : "auto",
          }}
        >
          <FormattedMessage id="admin.dashboard.saveAllChanges" />
        </Button>
      </Box>
    </Box>
  );
}
