import React from "react";
import {
  Box,
  Typography,
  Paper,
  TextField,
  Grid,
  Alert,
  Button,
} from "@mui/material";
import { Search as SearchIcon, Add as AddIcon } from "@mui/icons-material";
import { FormattedMessage, useIntl } from "react-intl";
import InstructorsList from "./InstructorsList/InstructorsList";
import DeleteInstructorDialog from "./DeleteInstructorDialog/DeleteInstructorDialog";
import EditInstructorDialog from "./EditInstructorDialog/EditInstructorDialog";
import CreateInstructorDialog from "./CreateInstructorDialog/CreateInstructorDialog";
import { useInstructorManagement } from "./useInstructorManagement";

export const InstructorManagement: React.FC = () => {
  const intl = useIntl();
  const {
    instructors,
    totalInstructors,
    error,
    deleteDialogOpen,
    editDialogOpen,
    createDialogOpen,
    instructorToEdit,
    page,
    rowsPerPage,
    orderBy,
    order,
    searchTerm,
    fetchInstructorData,
    handleDeleteInstructor,
    confirmDeleteInstructor,
    handleEditInstructor,
    handleUpdateInstructor,
    handleCreateInstructor,
    handleResetPassword,
    handleChangePage,
    handleChangeRowsPerPage,
    handleRequestSort,
    handleSearchChange,
    setDeleteDialogOpen,
    setEditDialogOpen,
    setCreateDialogOpen,
    setInstructorToEdit,
  } = useInstructorManagement();

  React.useEffect(() => {
    fetchInstructorData();
  }, [fetchInstructorData]);

  return (
    <Box>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Box sx={{ p: 2 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" component="div">
                <FormattedMessage id="admin.dashboard.instructors" />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <SearchIcon sx={{ mr: 1 }} />
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder={intl.formatMessage({
                    id: "admin.dashboard.searchPlaceholder",
                  })}
                  value={searchTerm}
                  onChange={handleSearchChange}
                  fullWidth
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ p: 2 }}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setCreateDialogOpen(true)}
          >
            <FormattedMessage id="admin.dashboard.createInstructor" />
          </Button>
        </Box>
        <InstructorsList
          instructors={instructors}
          totalInstructors={totalInstructors}
          page={page}
          rowsPerPage={rowsPerPage}
          orderBy={orderBy}
          order={order}
          onRequestSort={handleRequestSort}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onEditInstructor={handleEditInstructor}
          onDeleteInstructor={handleDeleteInstructor}
          onResetPassword={handleResetPassword}
        />
      </Paper>
      <DeleteInstructorDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={confirmDeleteInstructor}
      />
      <EditInstructorDialog
        open={editDialogOpen}
        instructorToEdit={instructorToEdit}
        onClose={() => setEditDialogOpen(false)}
        onSave={handleUpdateInstructor}
        onInstructorChange={setInstructorToEdit}
      />
      <CreateInstructorDialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        onSave={handleCreateInstructor}
      />
    </Box>
  );
};
