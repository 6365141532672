import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Tabs,
  Tab,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Typography,
  SelectChangeEvent,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import {
  fetchLocations,
  fetchTypes,
  fetchInstructors,
  createClass,
} from "../../../../redux/admin/adminOperations";
import ScheduleManager from "../ScheduleManager/ScheduleManager";
import Editor from "../../../../utils/styles/StyledEditor";
import { Class } from "../../../../redux/types/types";

interface CreateClassDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (classData: Omit<Class, "id">) => void;
}

type LanguageFields = {
  en: string;
  uk: string;
  fr: string;
};

type NewClass = {
  title: LanguageFields;
  description: LanguageFields;
  locationId: string;
  typeId: string;
  instructorId: string;
};

export default function CreateClassDialog({
  open,
  onClose,
  onSave,
}: CreateClassDialogProps) {
  const intl = useIntl();
  const dispatch = useDispatch<AppDispatch>();
  const [currentTab, setCurrentTab] = useState<"en" | "uk" | "fr">("en");
  const [dialogTab, setDialogTab] = useState<"class" | "schedule">("class");
  const locations = useSelector((state: RootState) => state.admin.locations);
  const types = useSelector((state: RootState) => state.admin.types);
  const instructors = useSelector(
    (state: RootState) => state.admin.instructors
  );
  const [isLoading, setIsLoading] = useState(false);

  const [newClass, setNewClass] = useState<NewClass>({
    title: { en: "", uk: "", fr: "" },
    description: { en: "", uk: "", fr: "" },
    locationId: "",
    typeId: "",
    instructorId: "",
  });

  const [isEnglishEmpty, setIsEnglishEmpty] = useState(true);
  const [createdClassId, setCreatedClassId] = useState<number | null>(null);

  useEffect(() => {
    setIsEnglishEmpty(
      newClass.title.en.trim() === "" || newClass.description.en.trim() === ""
    );
  }, [newClass.title.en, newClass.description.en]);

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      Promise.all([
        dispatch(
          fetchLocations({
            page: 1,
            limit: 100,
            sortBy: "id",
            sortOrder: "asc",
            search: "",
          })
        ),
        dispatch(
          fetchTypes({
            page: 1,
            limit: 100,
            sortBy: "id",
            sortOrder: "asc",
            search: "",
          })
        ),
        dispatch(
          fetchInstructors({
            page: 1,
            limit: 100,
            sortBy: "id",
            sortOrder: "asc",
            search: "",
          })
        ),
      ]).then(() => {
        setIsLoading(false);
      });
    }
  }, [open, dispatch]);

  useEffect(() => {
    if (locations.length > 0 && types.length > 0 && instructors.length > 0) {
      setNewClass((prevState) => ({
        ...prevState,
        locationId: locations[0].id.toString(),
        typeId: types[0].id.toString(),
        instructorId: instructors[0].id.toString(),
      }));
    }
  }, [locations, types, instructors]);

  const handleTitleChange = (value: string, lang: "en" | "uk" | "fr") => {
    setNewClass((prevClass) => ({
      ...prevClass,
      title: {
        ...prevClass.title,
        [lang]: value,
      },
    }));
  };

  const handleEditorChange = (content: string, lang: "en" | "uk" | "fr") => {
    setNewClass((prevClass) => ({
      ...prevClass,
      description: {
        ...prevClass.description,
        [lang]: content,
      },
    }));
  };

  const handleSelectChange =
    (field: "locationId" | "typeId" | "instructorId") =>
    (e: SelectChangeEvent) => {
      const value = e.target.value;
      setNewClass({
        ...newClass,
        [field]: value,
      });
    };

  const handleSave = () => {
    const selectedLocation = locations.find(
      (loc) => loc.id === parseInt(newClass.locationId, 10)
    );
    const selectedType = types.find(
      (type) => type.id === parseInt(newClass.typeId, 10)
    );
    const selectedInstructor = instructors.find(
      (inst) => inst.id === parseInt(newClass.instructorId, 10)
    );

    if (selectedLocation && selectedType && selectedInstructor) {
      const classData: Omit<Class, "id"> = {
        title: newClass.title,
        description: newClass.description,
        location: selectedLocation,
        type: selectedType,
        instructor: selectedInstructor,
        schedules: [],
      };
      onSave(classData);
    }

    handleClose();
  };

  const handleOpenSchedule = async () => {
    const selectedLocation = locations.find(
      (loc) => loc.id === parseInt(newClass.locationId, 10)
    );
    const selectedType = types.find(
      (type) => type.id === parseInt(newClass.typeId, 10)
    );
    const selectedInstructor = instructors.find(
      (inst) => inst.id === parseInt(newClass.instructorId, 10)
    );

    if (selectedLocation && selectedType && selectedInstructor) {
      const classData: Omit<Class, "id"> = {
        title: newClass.title,
        description: newClass.description,
        location: selectedLocation,
        type: selectedType,
        instructor: selectedInstructor,
        schedules: [],
      };

      dispatch(createClass(classData))
        .unwrap()
        .then((data) => {
          setCreatedClassId(data.id);
          setDialogTab("schedule");
        })
        .catch((error) => {
          console.error("Failed to create class:", error);
        });
    }
  };

  const handleClose = () => {
    onClose();
    setCurrentTab("en");
    setDialogTab("class");
    setCreatedClassId(null);
    setNewClass({
      title: { en: "", uk: "", fr: "" },
      description: { en: "", uk: "", fr: "" },
      locationId: "",
      typeId: "",
      instructorId: "",
    });
    setIsEnglishEmpty(true);
    setIsLoading(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <Tabs
        value={dialogTab}
        onChange={(_, newValue: "class" | "schedule") => setDialogTab(newValue)}
        aria-label="class creation tabs"
      >
        <Tab
          label={intl.formatMessage({ id: "admin.dashboard.class" })}
          value="class"
          disabled={Boolean(createdClassId)}
        />
        <Tab
          label={intl.formatMessage({ id: "admin.dashboard.schedule" })}
          value="schedule"
          disabled={isEnglishEmpty}
          onClick={handleOpenSchedule}
        />
      </Tabs>
      <DialogContent>
        <Typography variant="h6" sx={{ mb: 2 }}>
          <FormattedMessage id="admin.dashboard.createClass" />
        </Typography>
        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="200px"
          >
            <CircularProgress />
          </Box>
        ) : dialogTab === "class" ? (
          <>
            <Tabs
              value={currentTab}
              onChange={(_, newValue: "en" | "uk" | "fr") =>
                setCurrentTab(newValue)
              }
              aria-label="language tabs"
            >
              <Tab
                label={intl.formatMessage({ id: "common.english" })}
                value="en"
              />
              <Tab
                label={intl.formatMessage({ id: "common.ukrainian" })}
                value="uk"
                disabled={isEnglishEmpty}
              />
              <Tab
                label={intl.formatMessage({ id: "common.french" })}
                value="fr"
                disabled={isEnglishEmpty}
              />
            </Tabs>
            <Box sx={{ mt: 2 }}>
              <TextField
                autoFocus
                margin="dense"
                id={`title-${currentTab}`}
                label={intl.formatMessage({
                  id: "admin.dashboard.class.title",
                })}
                type="text"
                fullWidth
                value={newClass.title[currentTab]}
                onChange={(e) => handleTitleChange(e.target.value, currentTab)}
              />
              <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
                {intl.formatMessage({ id: "admin.dashboard.description" })}
              </Typography>
              <Editor
                content={newClass.description[currentTab]}
                onChange={(content) => handleEditorChange(content, currentTab)}
                placeholder={intl.formatMessage({
                  id: "admin.dashboard.description.placeholder",
                })}
              />
            </Box>
            <FormControl fullWidth margin="dense" sx={{ mt: 2, mb: 2 }}>
              <InputLabel id="location-select-label">
                <FormattedMessage id="admin.dashboard.location" />
              </InputLabel>
              <Select
                labelId="location-select-label"
                id="location-select"
                value={newClass.locationId}
                onChange={handleSelectChange("locationId")}
                label={intl.formatMessage({ id: "admin.dashboard.location" })}
              >
                {locations.map((location) => (
                  <MenuItem key={location.id} value={location.id.toString()}>
                    {location.name.en}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="dense" sx={{ mb: 2 }}>
              <InputLabel id="type-select-label">
                <FormattedMessage id="admin.dashboard.type" />
              </InputLabel>
              <Select
                labelId="type-select-label"
                id="type-select"
                value={newClass.typeId}
                onChange={handleSelectChange("typeId")}
                label={intl.formatMessage({ id: "admin.dashboard.type" })}
              >
                {types.map((type) => (
                  <MenuItem key={type.id} value={type.id.toString()}>
                    {type.name.en}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="dense">
              <InputLabel id="instructor-select-label">
                <FormattedMessage id="admin.dashboard.instructor" />
              </InputLabel>
              <Select
                labelId="instructor-select-label"
                id="instructor-select"
                value={newClass.instructorId}
                onChange={handleSelectChange("instructorId")}
                label={intl.formatMessage({ id: "admin.dashboard.instructor" })}
              >
                {instructors.map((instructor) => (
                  <MenuItem
                    key={instructor.id}
                    value={instructor.id.toString()}
                  >
                    {`${instructor.firstname} ${instructor.lastname}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        ) : (
          createdClassId && <ScheduleManager classId={createdClassId} />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          <FormattedMessage id="admin.dashboard.cancel" />
        </Button>
        {!createdClassId && (
          <Button onClick={handleSave} color="primary" disabled={isLoading}>
            <FormattedMessage id="admin.dashboard.create" />
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
