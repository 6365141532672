import api from "./axiosInstance";

export const fetchAttendeesApi = (params: {
  page: number;
  limit: number;
  sortBy: string;
  sortOrder: "asc" | "desc";
  search: string;
}) =>
  api.get("/attendee", {
    params: params,
  });

export const createAttendeeApi = (attendeeData: any) =>
  api.post("/attendee", attendeeData);

export const deleteAttendeeApi = (attendeeId: number) =>
  api.delete(`/attendee/${attendeeId}`);

export const getAttendeeApi = (attendeeId: number) =>
  api.get(`/attendee/${attendeeId}`);

export const updateAttendeeApi = (attendeeId: number, attendeeData: any) =>
  api.put(`/attendee/${attendeeId}`, attendeeData);

export const createAttendeeToClassApi = (enrollmentData: {
  attendeeId: number;
  classId: number;
}) => api.post("/attendee/class", enrollmentData);

export const fetchAttendeesByUserIdApi = (userId: number) =>
  api.get(`/attendee/user/${userId}`);

export const fetchClassPublicApi = async (classId: number) =>
  await api.get(`/classes/${classId}`);
