import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { User } from "../../../../redux/types/types";

interface EditUserDialogProps {
  open: boolean;
  userToEdit: User | null;
  onClose: () => void;
  onSave: () => void;
  onUserChange: (updatedUser: User) => void;
}

export default function EditUserDialog({
  open,
  userToEdit,
  onClose,
  onSave,
  onUserChange,
}: EditUserDialogProps) {
  const intl = useIntl();

  const handleChange =
    (field: keyof User) => (e: React.ChangeEvent<HTMLInputElement>) => {
      if (userToEdit) {
        onUserChange({ ...userToEdit, [field]: e.target.value });
      }
    };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
      disableEscapeKeyDown
      onBackdropClick={(event) => event.stopPropagation()}
    >
      <DialogTitle id="form-dialog-title">
        <FormattedMessage id="admin.dashboard.editUser" />
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="email"
          label={intl.formatMessage({ id: "admin.dashboard.email" })}
          type="email"
          fullWidth
          value={userToEdit?.email || ""}
          onChange={handleChange("email")}
        />
        <TextField
          margin="dense"
          id="firstname"
          label={intl.formatMessage({ id: "admin.dashboard.firstname" })}
          type="text"
          fullWidth
          value={userToEdit?.firstname || ""}
          onChange={handleChange("firstname")}
        />
        <TextField
          margin="dense"
          id="lastname"
          label={intl.formatMessage({ id: "admin.dashboard.lastname" })}
          type="text"
          fullWidth
          value={userToEdit?.lastname || ""}
          onChange={handleChange("lastname")}
        />
        <TextField
          margin="dense"
          id="phone"
          label={intl.formatMessage({ id: "admin.dashboard.phone" })}
          type="text"
          fullWidth
          value={userToEdit?.phone || ""}
          onChange={handleChange("phone")}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <FormattedMessage id="admin.dashboard.cancel" />
        </Button>
        <Button onClick={onSave}>
          <FormattedMessage id="admin.dashboard.save" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
