import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Notiflix from "notiflix";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  fetchUsers,
  deleteUser,
  updateUser,
  resetUserPassword,
} from "../../../redux/admin/adminOperations";
import { User } from "../../../redux/types/types";

type Order = "asc" | "desc";

export const useUserManagement = () => {
  const dispatch = useDispatch<AppDispatch>();
  const intl = useIntl();
  const { users, totalUsers, loading, error } = useSelector(
    (state: RootState) => state.admin
  );

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState<number | null>(null);
  const [userToEdit, setUserToEdit] = useState<User | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [orderBy, setOrderBy] = useState<keyof User>("id");
  const [order, setOrder] = useState<Order>("asc");
  const [searchTerm, setSearchTerm] = useState("");

  const fetchUserData = useCallback(() => {
    dispatch(
      fetchUsers({
        page: page + 1,
        limit: rowsPerPage,
        sortBy: orderBy,
        sortOrder: order,
        search: searchTerm,
      })
    );
  }, [dispatch, page, rowsPerPage, orderBy, order, searchTerm]);

  const handleDeleteUser = useCallback((userId: number) => {
    setUserToDelete(userId);
    setDeleteDialogOpen(true);
  }, []);

  const confirmDeleteUser = useCallback(() => {
    if (userToDelete) {
      dispatch(deleteUser(userToDelete))
        .unwrap()
        .then(() => {
          fetchUserData();
          setDeleteDialogOpen(false);
          setUserToDelete(null);
        })
        .catch((error) => {
          console.error("Failed to delete user:", error);
        });
    }
  }, [userToDelete, dispatch, fetchUserData]);

  const handleEditUser = useCallback((user: User) => {
    setUserToEdit(user);
    setEditDialogOpen(true);
  }, []);

  const handleUpdateUser = useCallback(() => {
    if (userToEdit && userToEdit.id) {
      dispatch(updateUser({ userId: userToEdit.id, userData: userToEdit }))
        .unwrap()
        .then(() => {
          fetchUserData();
          setEditDialogOpen(false);
          setUserToEdit(null);
        })
        .catch((error) => {
          console.error("Failed to update user:", error);
        });
    }
  }, [userToEdit, dispatch, fetchUserData]);

  const handleResetPassword = useCallback(
    (userId: number) => {
      dispatch(resetUserPassword(userId))
        .unwrap()
        .then(() => {
          Notiflix.Notify.success(
            intl.formatMessage({ id: "admin.dashboard.passwordResetSuccess" })
          );
        })
        .catch((error) => {
          Notiflix.Notify.failure("Failed to reset password:", error);
        });
    },
    [dispatch, intl]
  );

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    },
    []
  );

  const handleRequestSort = useCallback(
    (property: keyof User) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    },
    [order, orderBy]
  );

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
      setPage(0);
    },
    []
  );

  return {
    users,
    totalUsers,
    loading,
    error,
    deleteDialogOpen,
    editDialogOpen,
    userToDelete,
    userToEdit,
    page,
    rowsPerPage,
    orderBy,
    order,
    searchTerm,
    fetchUserData,
    handleDeleteUser,
    confirmDeleteUser,
    handleEditUser,
    handleUpdateUser,
    handleResetPassword,
    handleChangePage,
    handleChangeRowsPerPage,
    handleRequestSort,
    handleSearchChange,
    setDeleteDialogOpen,
    setEditDialogOpen,
    setUserToEdit,
  };
};
