import api from "./axiosInstance";

export const fetchUsersApi = (params: {
  page: number;
  limit: number;
  sortBy: string;
  sortOrder: "asc" | "desc";
  search: string;
}) =>
  api.get("/admin/users", {
    params: params,
  });

export const fetchInstructorsApi = (params: {
  page: number;
  limit: number;
  sortBy: string;
  sortOrder: "asc" | "desc";
  search: string;
}) =>
  api.get("/admin/instructors", {
    params: params,
  });

export const createUserApi = (userData: any) =>
  api.post("/admin/users", userData);

export const createInstructorApi = (instructorData: any) =>
  api.post("/admin/instructors", instructorData);

export const deleteUserApi = (userId: number) =>
  api.delete(`/admin/users/${userId}`);

export const deleteInstructorApi = (instructorId: number) =>
  api.delete(`/admin/instructors/${instructorId}`);

export const getUserApi = (userId: number) => api.get(`/admin/users/${userId}`);

export const getInstructorApi = (instructorId: number) =>
  api.get(`/admin/instructors/${instructorId}`);

export const updateUserApi = (userId: number, userData: any) =>
  api.patch(`/admin/users/${userId}`, userData);

export const updateInstructorApi = (
  instructorId: number,
  instructorData: any
) => api.patch(`/admin/instructors/${instructorId}`, instructorData);

export const resetUserPasswordApi = (userId: number) =>
  api.post(`/admin/users/${userId}/reset-password`);

export const resetInstructorPasswordApi = (instructorId: number) =>
  api.post(`/admin/instructors/${instructorId}/reset-password`);
