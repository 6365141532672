import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Avatar,
  Box,
  Button,
  TextField,
  Typography,
  Alert,
  CircularProgress,
  Paper,
  Select,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { AppDispatch, RootState } from "../../redux/store";
import { loginAdmin } from "../../redux/auth/authOperations";
import { FormattedMessage, useIntl } from "react-intl";
import { useLanguage } from "../LanguageProvider/LanguageProvider";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

export default function AdminSignInForm() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [serverError, setServerError] = useState<string | null>(null);
  const isAdmin = useSelector((state: RootState) => state.auth.isAdmin);
  const loading = useSelector((state: RootState) => state.auth.loading);
  const { language, setLanguage } = useLanguage();
  const intl = useIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (isAdmin) {
      navigate("/admin/dashboard");
    }
  }, [isAdmin, navigate]);

  const handleSubmit = async (values: { email: string; password: string }) => {
    try {
      await dispatch(loginAdmin({ ...values, remember: false })).unwrap();
      setServerError(null);
    } catch (error: any) {
      setServerError(error.message || "An error occurred during login");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#f0f0f0",
        padding: 2,
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: isMobile ? 2 : 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          maxWidth: 400,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            mb: 2,
          }}
        >
          <Select
            value={language}
            onChange={(e) => setLanguage(e.target.value as "en" | "fr" | "uk")}
            size="small"
          >
            <MenuItem value="en">EN</MenuItem>
            <MenuItem value="fr">FR</MenuItem>
            <MenuItem value="uk">UK</MenuItem>
          </Select>
        </Box>
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" sx={{ mb: 3 }}>
          <FormattedMessage id="admin.signin.title" />
        </Typography>
        {serverError && (
          <Alert severity="error" sx={{ mt: 2, width: "100%" }}>
            {serverError}
          </Alert>
        )}
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form noValidate style={{ width: "100%" }}>
              <Field
                as={TextField}
                margin="normal"
                required
                fullWidth
                id="email"
                label={intl.formatMessage({ id: "admin.signin.email" })}
                name="email"
                autoComplete="email"
                error={touched.email && !!errors.email}
                helperText={touched.email && errors.email}
              />
              <Field
                as={TextField}
                margin="normal"
                required
                fullWidth
                name="password"
                label={intl.formatMessage({ id: "admin.signin.password" })}
                type="password"
                id="password"
                autoComplete="current-password"
                error={touched.password && !!errors.password}
                helperText={touched.password && errors.email}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: "#4caf50",
                  "&:hover": { backgroundColor: "#45a049" },
                }}
                disabled={isSubmitting || loading}
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  <FormattedMessage id="admin.signin.submit" />
                )}
              </Button>
            </Form>
          )}
        </Formik>
      </Paper>
    </Box>
  );
}
