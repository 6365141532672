import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Tabs,
  Tab,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Typography,
  SelectChangeEvent,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import {
  fetchLocations,
  fetchTypes,
  fetchInstructors,
} from "../../../../redux/admin/adminOperations";
import ScheduleManager from "../ScheduleManager/ScheduleManager";
import Editor from "../../../../utils/styles/StyledEditor";
import { Class } from "../../../../redux/types/types";

interface EditClassDialogProps {
  open: boolean;
  classToEdit: Class | null;
  onClose: () => void;
  onSave: (updatedClass: Class) => void;
}

export default function EditClassDialog({
  open,
  classToEdit,
  onClose,
  onSave,
}: EditClassDialogProps) {
  const intl = useIntl();
  const dispatch = useDispatch<AppDispatch>();
  const [currentTab, setCurrentTab] = useState<"en" | "uk" | "fr">("en");
  const [dialogTab, setDialogTab] = useState<"class" | "schedule">("class");
  const locations = useSelector((state: RootState) => state.admin.locations);
  const types = useSelector((state: RootState) => state.admin.types);
  const instructors = useSelector(
    (state: RootState) => state.admin.instructors
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isEnglishEmpty, setIsEnglishEmpty] = useState(false);
  const [localClass, setLocalClass] = useState<Class | null>(null);

  useEffect(() => {
    if (classToEdit) {
      setLocalClass(classToEdit);
      setIsEnglishEmpty(
        classToEdit.title.en.trim() === "" ||
          classToEdit.description.en.trim() === ""
      );
    }
  }, [classToEdit]);

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      Promise.all([
        dispatch(
          fetchLocations({
            page: 1,
            limit: 100,
            sortBy: "id",
            sortOrder: "asc",
            search: "",
          })
        ),
        dispatch(
          fetchTypes({
            page: 1,
            limit: 100,
            sortBy: "id",
            sortOrder: "asc",
            search: "",
          })
        ),
        dispatch(
          fetchInstructors({
            page: 1,
            limit: 100,
            sortBy: "id",
            sortOrder: "asc",
            search: "",
          })
        ),
      ]).then(() => {
        setIsLoading(false);
      });
    }
  }, [open, dispatch]);

  const handleTitleChange = (value: string, lang: "en" | "uk" | "fr") => {
    if (localClass) {
      setLocalClass({
        ...localClass,
        title: {
          ...localClass.title,
          [lang]: value,
        },
      });
    }
  };

  const handleEditorChange = (content: string, lang: "en" | "uk" | "fr") => {
    if (localClass) {
      setLocalClass((prevClass) => ({
        ...prevClass!,
        description: {
          ...prevClass!.description,
          [lang]: content,
        },
      }));
    }
  };

  const handleSelectChange =
    (field: "location" | "type" | "instructor") => (e: SelectChangeEvent) => {
      if (localClass) {
        const value = parseInt(e.target.value, 10);
        let updatedField;
        switch (field) {
          case "location":
            updatedField = locations.find((loc) => loc.id === value);
            break;
          case "type":
            updatedField = types.find((type) => type.id === value);
            break;
          case "instructor":
            updatedField = instructors.find((inst) => inst.id === value);
            break;
        }
        if (updatedField) {
          setLocalClass({
            ...localClass,
            [field]: updatedField,
          });
        }
      }
    };

  const handleClose = () => {
    onClose();
    setCurrentTab("en");
    setDialogTab("class");
    setIsLoading(false);
    setLocalClass(null);
  };

  const handleSave = () => {
    if (localClass) {
      onSave(localClass);
    }
    handleClose();
  };

  if (!localClass) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <Tabs
        value={dialogTab}
        onChange={(_, newValue: "class" | "schedule") => setDialogTab(newValue)}
        aria-label="class edit tabs"
      >
        <Tab
          label={intl.formatMessage({ id: "admin.dashboard.class" })}
          value="class"
        />
        <Tab
          label={intl.formatMessage({ id: "admin.dashboard.schedule" })}
          value="schedule"
        />
      </Tabs>
      <DialogContent>
        <Typography variant="h6" sx={{ mb: 2 }}>
          <FormattedMessage id="admin.dashboard.editClass" />
        </Typography>
        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="200px"
          >
            <CircularProgress />
          </Box>
        ) : dialogTab === "class" ? (
          <>
            <Tabs
              value={currentTab}
              onChange={(_, newValue: "en" | "uk" | "fr") =>
                setCurrentTab(newValue)
              }
              aria-label="language tabs"
            >
              <Tab
                label={intl.formatMessage({ id: "common.english" })}
                value="en"
              />
              <Tab
                label={intl.formatMessage({ id: "common.ukrainian" })}
                value="uk"
                disabled={isEnglishEmpty}
              />
              <Tab
                label={intl.formatMessage({ id: "common.french" })}
                value="fr"
                disabled={isEnglishEmpty}
              />
            </Tabs>
            <Box sx={{ mt: 2 }}>
              <TextField
                autoFocus
                margin="dense"
                id={`title-${currentTab}`}
                label={intl.formatMessage({
                  id: "admin.dashboard.class.title",
                })}
                type="text"
                fullWidth
                value={localClass.title[currentTab]}
                onChange={(e) => handleTitleChange(e.target.value, currentTab)}
              />
              <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>
                {intl.formatMessage({ id: "admin.dashboard.description" })}
              </Typography>
              <Editor
                content={localClass.description[currentTab]}
                onChange={(content) => handleEditorChange(content, currentTab)}
                placeholder={intl.formatMessage({
                  id: "admin.dashboard.description.placeholder",
                })}
              />
            </Box>
            <FormControl fullWidth margin="dense" sx={{ mt: 2, mb: 2 }}>
              <InputLabel id="location-select-label">
                <FormattedMessage id="admin.dashboard.location" />
              </InputLabel>
              <Select
                labelId="location-select-label"
                id="location-select"
                value={localClass.location.id.toString()}
                onChange={handleSelectChange("location")}
                label={intl.formatMessage({ id: "admin.dashboard.location" })}
              >
                {locations.map((location) => (
                  <MenuItem key={location.id} value={location.id.toString()}>
                    {location.name.en}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="dense" sx={{ mb: 2 }}>
              <InputLabel id="type-select-label">
                <FormattedMessage id="admin.dashboard.type" />
              </InputLabel>
              <Select
                labelId="type-select-label"
                id="type-select"
                value={localClass.type.id.toString()}
                onChange={handleSelectChange("type")}
                label={intl.formatMessage({ id: "admin.dashboard.type" })}
              >
                {types.map((type) => (
                  <MenuItem key={type.id} value={type.id.toString()}>
                    {type.name.en}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="dense">
              <InputLabel id="instructor-select-label">
                <FormattedMessage id="admin.dashboard.instructor" />
              </InputLabel>
              <Select
                labelId="instructor-select-label"
                id="instructor-select"
                value={localClass.instructor.id.toString()}
                onChange={handleSelectChange("instructor")}
                label={intl.formatMessage({ id: "admin.dashboard.instructor" })}
              >
                {instructors.map((instructor) => (
                  <MenuItem
                    key={instructor.id}
                    value={instructor.id.toString()}
                  >
                    {`${instructor.firstname} ${instructor.lastname}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        ) : (
          <ScheduleManager classId={localClass.id} />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          <FormattedMessage id="admin.dashboard.cancel" />
        </Button>
        {dialogTab === "class" && (
          <Button onClick={handleSave} color="primary" disabled={isLoading}>
            <FormattedMessage id="admin.dashboard.save" />
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
