import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Link as LinkMaterial,
  Alert,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { FormattedMessage, useIntl } from "react-intl";

import { AppDispatch } from "../../../redux/store";
import { registerUser } from "../../../redux/auth/authOperations";
import { Link } from "react-router-dom";
import CustomTextField from "../../CustomTextField/CustomTextField";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      <FormattedMessage
        id="copyright"
        values={{
          year: new Date().getFullYear(),
          website: (chunks: any) => (
            <LinkMaterial color="inherit" href="https://mui.com/">
              {chunks}
            </LinkMaterial>
          ),
        }}
      />
    </Typography>
  );
}

export default function SignUpForm() {
  const dispatch = useDispatch<AppDispatch>();
  const [serverError, setServerError] = useState<string | null>(null);
  const intl = useIntl();

  const validationSchema = Yup.object({
    firstname: Yup.string()
      .required(intl.formatMessage({ id: "signUp.firstNameRequired" }))
      .min(2, intl.formatMessage({ id: "signUp.firstNameMinLength" }))
      .max(50, intl.formatMessage({ id: "signUp.firstNameMaxLength" })),
    lastname: Yup.string()
      .required(intl.formatMessage({ id: "signUp.lastNameRequired" }))
      .min(2, intl.formatMessage({ id: "signUp.lastNameMinLength" }))
      .max(50, intl.formatMessage({ id: "signUp.lastNameMaxLength" })),
    email: Yup.string()
      .email(intl.formatMessage({ id: "signUp.invalidEmail" }))
      .required(intl.formatMessage({ id: "signUp.emailRequired" })),
    phone: Yup.string().required(
      intl.formatMessage({ id: "signUp.phoneRequired" })
    ),
    password: Yup.string()
      .required(intl.formatMessage({ id: "signUp.passwordRequired" }))
      .min(8, intl.formatMessage({ id: "signUp.passwordMinLength" })),
  });

  const handleSubmit = async (values: {
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    password: string;
  }) => {
    try {
      await dispatch(registerUser(values)).unwrap();
      setServerError(null);
    } catch (error: any) {
      setServerError(
        error || intl.formatMessage({ id: "signUp.genericErrorMessage" })
      );
    }
  };

  return (
    <Box
      sx={{
        my: 12,
        mx: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        <FormattedMessage id="signUp.title" />
      </Typography>
      {serverError && (
        <Alert severity="error" sx={{ mt: 2, width: "100%" }}>
          {serverError}
        </Alert>
      )}
      <Formik
        initialValues={{
          firstname: "",
          lastname: "",
          email: "",
          phone: "",
          password: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, isSubmitting, setFieldValue, values }) => (
          <Form noValidate style={{ width: "100%" }}>
            <Field
              as={TextField}
              margin="normal"
              required
              fullWidth
              id="firstname"
              label={intl.formatMessage({ id: "signUp.firstNameLabel" })}
              name="firstname"
              autoComplete="given-name"
              error={touched.firstname && !!errors.firstname}
              helperText={touched.firstname && errors.firstname}
            />
            <Field
              as={TextField}
              margin="normal"
              required
              fullWidth
              id="lastname"
              label={intl.formatMessage({ id: "signUp.lastNameLabel" })}
              name="lastname"
              autoComplete="family-name"
              error={touched.lastname && !!errors.lastname}
              helperText={touched.lastname && errors.lastname}
            />
            <Field
              as={TextField}
              margin="normal"
              required
              fullWidth
              id="email"
              label={intl.formatMessage({ id: "signUp.emailLabel" })}
              name="email"
              autoComplete="email"
              error={touched.email && !!errors.email}
              helperText={touched.email && errors.email}
            />
            <div style={{ margin: "16px 0" }}>
              <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry="US"
                value={values.phone}
                onChange={(value) => setFieldValue("phone", value)}
                inputComponent={CustomTextField as any}
              />
              {touched.phone && errors.phone && (
                <Typography color="error" variant="caption">
                  {errors.phone}
                </Typography>
              )}
            </div>
            <Field
              as={TextField}
              margin="normal"
              required
              fullWidth
              name="password"
              label={intl.formatMessage({ id: "signUp.passwordLabel" })}
              type="password"
              id="password"
              autoComplete="new-password"
              error={touched.password && !!errors.password}
              helperText={touched.password && errors.password}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isSubmitting}
            >
              <FormattedMessage id="signUp.submitButton" />
            </Button>
          </Form>
        )}
      </Formik>
      <Grid container>
        <Grid item>
          <Link
            to="/auth/signin"
            style={{ color: "#1976d2", fontSize: "14px" }}
          >
            <FormattedMessage id="signUp.signInLink" />
          </Link>
        </Grid>
      </Grid>
      <Copyright sx={{ mt: 5 }} />
    </Box>
  );
}
