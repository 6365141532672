import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createAttendee,
  deleteAttendee,
  enrollAttendeeToClass,
  fetchClassPublic,
  updateAttendee,
} from "./enrollmentOperations";
import { Attendee, EnrollmentState } from "../types/types";

const initialState: EnrollmentState = {
  enrollment: null,
  class: null,
  loading: false,
  error: null,
};

const enrollmentSlice = createSlice({
  name: "enrollment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createAttendee.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        createAttendee.fulfilled,
        (state, action: PayloadAction<Attendee>) => {
          state.loading = false;
        }
      )
      .addCase(createAttendee.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to create attendee";
      })
      .addCase(updateAttendee.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        updateAttendee.fulfilled,
        (state, action: PayloadAction<Attendee>) => {
          state.loading = false;
        }
      )
      .addCase(updateAttendee.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to update attendee";
      })
      .addCase(deleteAttendee.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        deleteAttendee.fulfilled,
        (state, action: PayloadAction<number>) => {
          state.loading = false;
        }
      )
      .addCase(deleteAttendee.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to delete attendee";
      })
      .addCase(enrollAttendeeToClass.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(enrollAttendeeToClass.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(enrollAttendeeToClass.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to enroll attendee";
      })
      .addCase(fetchClassPublic.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchClassPublic.fulfilled, (state, action) => {
        state.class = action.payload;
        state.loading = false;
      })
      .addCase(fetchClassPublic.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch class";
      });
  },
});

export default enrollmentSlice.reducer;
